import React, { ReactNode } from 'react';
import { useSpring, animated } from '@react-spring/web'

interface AnimatedUnderlineProps {
  children: ReactNode;
}

const AnimatedUnderline: React.FC<AnimatedUnderlineProps> = ({ children }) => {
  const [hovered, setHovered] = React.useState(false);

  const underlineAnimation = useSpring({
    transform: hovered ? 'scaleX(1)' : 'scaleX(0)',
  });

  return (
    <span
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{ position: 'relative', display: 'inline-block' }}
    >
      {children}
      <animated.div
        style={{
          position: 'absolute',
          left: 0,
          bottom: -2,
          width: '100%',
          height: 2,
          backgroundColor: '#000', // Change color as needed
          transformOrigin: 'bottom left',
          cursor: 'pointer !important',
          ...underlineAnimation,
        }}
      />
    </span>
  );
};

export default AnimatedUnderline;
