import { allSites } from "@/firebase/data/site";
import { ISite } from "@ehabitation/ts-utils/browser";
import { useEffect, useState } from "react";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectItem,
} from "../ui/select";
import { GoogleMapsForm } from "./google-maps-form";
import AnimatedUnderline from "../ui/animations/underline-animation";
import { animated, useSpring } from "@react-spring/web";
import { useEvents } from "@/context/events-context";

interface SiteSelectorProps extends React.HTMLAttributes<HTMLDivElement> {
  site: ISite | undefined;
  setSite: React.Dispatch<React.SetStateAction<ISite | undefined>>;
  userId: string;
  setDisplayLocationSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

export function SiteSelector({
  site,
  setSite,
  userId,
  setDisplayLocationSearch,
}: SiteSelectorProps) {
  const [sites, setSites] = useState<ISite[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const MAX_NUMBER_FREE_SITES = 1;
  const [loopCount, setLoopCount] = useState(0);
  const maxLoops = 3;
  const {events} = useEvents()

  // React Spring animation for the pulsating effect
  const styles = useSpring({
    from: { borderColor: 'rgba(110, 231, 166, 0)' },
    to: async (next) => {
      while (loopCount < maxLoops) {
        await next({ borderColor: 'rgba(110, 231, 166, 1)' });
        await next({ borderColor: 'rgba(255, 255, 255, 0)' });
        setLoopCount(prev => prev + 1);
      }
    },
    config: { duration: 2000 }
  });

  useEffect(() => {
    const fetchSites = async () => {
      try {
        setLoading(true);
        const loadedSites = await allSites(userId);
        console.log("Sites loaded:", loadedSites);
        const filteredSites = loadedSites.filter(site => site.onboardingProgressStep || site.onboardingProgressMessage);
        console.log("Filtered Sites:", filteredSites);
        setSites(filteredSites);
        setLoading(false);
      } catch (err) {
        console.error("Failed to fetch sites:", err);
        setError("Failed to load sites");
        setLoading(false);
      }
    };
    fetchSites();
  }, [site]);

  const handleSelectChange = (selectedSiteId: string) => {
    const selectedSite = sites.find((site) => site.id === selectedSiteId);
    if (selectedSite) {
      events.open_site(selectedSite.id)
      setSite(selectedSite);
      setDisplayLocationSearch(false);
    }
  };
  return (
    <>
      <animated.div
        style={styles}
        className="space-y-4 py-4 rounded-lg border shadow-sm"
      >
        <div className="flex justify-between items-center mb-2 px-4">
        <span className="text-lg font-semibold tracking-tight">Start here</span>
        
      </div>
        <div className="px-2 py-2">
          {loading ? (
            <Select onValueChange={(value) => handleSelectChange(value)}>
              <SelectTrigger className="w-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="animate-spin"
                >
                  <path d="M21 12a9 9 0 1 1-6.219-8.56" />
                </svg>
                <SelectValue
                  placeholder="Loading sites..."
                  className="text-left "
                />
              </SelectTrigger>
            </Select>
          ) : error ? (
            <p className="text-xs text-muted-foreground">
              Error loading sites: {error}
            </p>
          ) : (
            <>
              {Object.keys(sites).length > 0 && (
                <div className="ml-2 mr-2">
                  <Select onValueChange={(value) => handleSelectChange(value)}>
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select a site" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {sites.map((site, index) => (
                          <SelectItem key={index} value={site.id}>
                            {site.name} ({site.location.latitude},
                            {site.location.longitude}){" "}
                            {site.creationDate.toLocaleDateString()}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  <div className="px-6 pt-8 pb-6">
                    <div className="relative">
                      <div className="absolute inset-0 flex items-center">
                        <span className="w-full border-t" />
                      </div>
                      <div className="relative flex justify-center text-xs uppercase">
                        <span className="bg-background px-2 text-muted-foreground">
                          Or
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {Object.keys(sites).length < MAX_NUMBER_FREE_SITES ? (
                <>
                  {/* {Object.keys(sites).length > 0 && (
                    
                  )} */}
                  <div>
                    <GoogleMapsForm
                      setSite={setSite}
                      setDisplayLocationSearch={setDisplayLocationSearch}
                    />
                    <span className="text-xs px-6 bg-background text-muted-foreground">
                      You have{" "}
                      {MAX_NUMBER_FREE_SITES - Object.keys(sites).length} free
                      sites left.
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div className="px-4 py-2">
                    <AnimatedUnderline>
                      <span className="text-xs bg-background text-muted-foreground">
                        <a href="mailto:info+freesites@ehab.co">
                          You have run out of free sites. Reach out to get some
                          more.
                        </a>
                      </span>
                    </AnimatedUnderline>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </animated.div>
    </>
  );
}
