import { useContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import RequireAuth from "./components/require-auth";
import { AuthContext } from "./context/auth-context";
import Dashboard from "./routes/dashboard";
import Home from "./routes/home";
import { EventsContextProvider } from "./context/events-context";

function App() {
  // const { loading } = useContext(AuthContext);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!isDesktop) {
    return (
      <div className="text-center p-10 text-sm">
        <img
          src="/ehab-logo.svg"
          className="h-[30px] mx-auto mt-12 mb-8"
          alt="EHAB Logo"
        />
        <p className="mt-6 mb-6">
          Thank you for your interest, but EHAB's ClimaCast is not available on
          mobile yet.
        </p>
        <p className="mt-2 text-xs">
          Visit us at{" "}
          <a href="https://ehab.co" className="underline hover:text-primary">
            ehab.co
          </a>
        </p>
        <p className="mt-2 text-xs">
          Contact us at{" "}
          <a
            href="mailto:info@ehab.co"
            className="underline hover:text-primary"
          >
            info@ehab.co
          </a>
        </p>
      </div>
    );
  }

  return (
    <EventsContextProvider>
    <Routes>
        <Route index element={<Home />} />
        <Route
          path="dashboard"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        />
    </Routes>
    </EventsContextProvider>
  );
}

export default App;
