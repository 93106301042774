import { IRule, IRules } from "@/lib/types";
import { useState } from "react";
import { NewRuleCard } from "./new-rule-card";
import { RuleCard } from "./rule-card";


interface ThresholdSettingsProps extends React.HTMLAttributes<HTMLDivElement> {
  rules: IRules;
  setRules: React.Dispatch<React.SetStateAction< IRules>>;
}

export function ThresholdSettings({
  rules,
  setRules,
}: ThresholdSettingsProps) {
  const [editingRule, setEditingRule] = useState<IRule>();

  return (
    <>
      {Object.keys(rules).map((key, index) => {
        const rule = rules[key];
        return (
          <>
            <div
              key={rule.name + "_" + index} //TODO Assuming rule names are unique
              className="mb-4 flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent"
              onClick={() => setEditingRule(rule)}
            >
              <RuleCard
                rule={rule}
                rules={rules}
                setRules={setRules}
              />
            </div>
          </>
        );
      })}
      <div className="flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent">
        <NewRuleCard rules={rules} setRules={setRules} />
      </div>

      {/* <div className="flex flex-col gap-2 pt-6">
        <Button type="button" onClick={updateReport}>
          Update Report
        </Button>
      </div> */}
    </>
  );
}
