import { ZoomOutIcon } from "@radix-ui/react-icons";
import "chartjs-adapter-date-fns";
import zoomPlugin from "chartjs-plugin-zoom";
import { enGB } from "date-fns/locale";
import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";

import { config } from "@/firebase/firebase-config";
import { ISite } from "@ehabitation/ts-utils/browser";

import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from "chart.js";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

import AnimatedUnderline from "../ui/animations/underline-animation";
import { useEvents } from "@/context/events-context";
import { Button } from "../ui/button";

// Register the Chart.js components we will use
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
  TimeScale
);

interface WeatherDataEntry {
  validTimeUtc: string; // ISO string
  temperature_2m_mean: number;
  temperature_2m_min: number;
  temperature_2m_max: number;
  wind_speed_10m_mean: number;
  wind_speed_10m_max: number;
  precipitation_sum: number;
}

interface WeatherData {
  data: WeatherDataEntry[];
}

interface LongtermWeatherChartProps
  extends React.HTMLAttributes<HTMLDivElement> {
  site?: ISite;

}


export function LongtermWeatherCharts({ site }: LongtermWeatherChartProps) {
  const [weatherData, setWeatherData] = useState<WeatherDataEntry[]>([]);
  const temperatureChartRef = useRef(null);
  const windSpeedChartRef = useRef(null);
  const precipitationChartRef = useRef(null);
  const {events} = useEvents()

  useEffect(() => {
    events.view_longterm_section(site!.id)
    const storage = getStorage(undefined, `gs://${config.storageBucket}`);
    const fileRef = ref(
      storage,
      // "climateGraphs/climate_for_site_A871kwctVERCyjx1frdo.json"
      `climateGraphs/climate_for_site_${site?.id}.json`
    );

    getDownloadURL(fileRef)
      .then((url) => fetch(url))
      .then((response) => response.json())
      .then((data: WeatherData) => {
        // const formattedData = data.data.map(entry => {
        //   return {
        //     ...entry,
        //     validTimeUtc: new Date(entry.validTimeUtc).toLocaleDateString('en-US', {
        //       month: 'short',
        //       year: '2-digit'
        //     })
        //   };
        // });
        // setWeatherData(formattedData);
        setWeatherData(data.data);
      })
      .catch((error) =>
        console.error("Error fetching or processing data:", error)
      );
  }, []);

  const options = {
    responsive: true,
    elements: {
      point: {
        radius: 0, // removes dots from the line
      },
    },
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          drag: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: "x" as const,
        },
      },
      title: {
        display: false,
        // text: "Chart.js Line Chart",
        tooltipFormat: "MMM D, YYYY",
      },
    },
    scales: {
      x: {
        type: "time" as const,
        time: {
          // unit: 'month',
          // tooltipFormat: "MMM YYYY",
          // displayFormats: {
          //   month: 'MMM YY'
          // },
          // parser: 'YYYY-MM-DD', // Update the parser based on your date format
        },
        title: {
          display: true,
          text: "Date",
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 22,
        },
        adapters: {
          date: {
            locale: enGB,
          },
        },
      },
    },
  };

  const temperatureData = {
    labels: weatherData.map((data) => new Date(data.validTimeUtc)),
    datasets: [
      {
        label: "Mean Temperature",
        data: weatherData.map((data) => data.temperature_2m_mean),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        borderWidth: 1,
      },
      {
        label: "Min Temperature",
        data: weatherData.map((data) => data.temperature_2m_min),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        borderWidth: 0.5,
      },
      {
        label: "Max Temperature",
        data: weatherData.map((data) => data.temperature_2m_max),
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgba(75, 192, 192, 0.5)",
        borderWidth: 0.5,
      },
    ],
  };

  const windSpeedData = {
    labels: weatherData.map((data) => new Date(data.validTimeUtc)),
    datasets: [
      {
        label: "Mean Wind Speed",
        data: weatherData.map((data) => data.wind_speed_10m_mean),
        borderColor: "rgb(88, 101, 242)",
        backgroundColor: "rgba(88, 101, 242, 0.5)",
        borderWidth: 0.5,
      },
      {
        label: "Max Wind Speed",
        data: weatherData.map((data) => data.wind_speed_10m_max),
        borderColor: "rgb(242, 114, 88)",
        backgroundColor: "rgba(242, 114, 88, 0.5)",
        borderWidth: 0.5,
      },
    ],
  };

  const precipitationData = {
    labels: weatherData.map((data) => new Date(data.validTimeUtc)),
    datasets: [
      {
        label: "Precipitation",
        data: weatherData.map((data) => data.precipitation_sum),
        borderColor: "rgb(93, 164, 214)",
        backgroundColor: "rgba(93, 164, 214, 0.5)",
        borderWidth: 0.5,
      },
    ],
  };

  const resetZoom = (chartRef: any) => {
    if (chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  
  return (
    <>
      <div className="pb-4">
        {/* <h2 className="text-2xl font-semibold tracking-tight pb-4">
          XXXXXXXX
        </h2> */}
        <div className="text-xs text-muted-foreground">
          <h6 className="pb-2">
            Climate model:{" "}
            <AnimatedUnderline>
              <a
                href="https://www.wdc-climate.de/ui/cmip6?input=CMIP6.HighResMIP.EC-Earth-Consortium.EC-Earth3P-HR"
                target="_blank"
              >
                EC-Earth3P-HR, by the EC‐Earth consortium
              </a>
            </AnimatedUnderline>
          </h6>
          <p className="pb-2">
            The EC-Earth3P-HR model is a high-resolution global coupled climate
            model developed as part of the EC-Earth consortium's contributions
            to the High Resolution Model Intercomparison Project (HighResMIP).
            It operates with an atmospheric resolution of approximately 40 km
            and an oceanic resolution of 0.25 degrees. This model is designed to
            run alongside a standard-resolution version, EC-Earth3P, which has
            an atmospheric resolution of 80 km and an oceanic resolution of 1.0
            degrees.
          </p>
          <p>
            The EC‐Earth consortium brings together 30 research institutes from
            12 European countries to collaborate on the development of an Earth
            System Model.
          </p>
        </div>
      </div>
      <div className="chart-container">
        <div className="flex items-center justify-between pt-6 pb-4">
          <div className="space-y-1">
            <h2 className="text-2xl font-semibold tracking-tight">
              Temperature
            </h2>
            <p className="text-xs text-muted-foreground">
              Maximum, minimum and mean daily air temperature at 2 meters above
              ground. Additionally, temperature is downscaled using a 90-meter
              digital elevation model. Climate models are not perfect, and they
              have inherent uncertainties and biases that can affect the
              accuracy of their temperature predictions. However, the
              temperature anomaly over a long period of time is a good indicator
              the future Earth's climate. The following paper analyses the{" "}
              <AnimatedUnderline>
                <a
                  href="https://agupubs.onlinelibrary.wiley.com/doi/full/10.1029/2020EF001667"
                  target="_blank"
                >
                  robustness of CMIP6 temperature predictions
                </a>
              </AnimatedUnderline>
              .
            </p>
          </div>
          <Button
            variant="secondary"
            type="button"
            className="text-xs ml-4"
            onClick={() => resetZoom(temperatureChartRef)}
          >
            <ZoomOutIcon className="mr-2 h-4 w-4" />
            reset zoom
          </Button>
        </div>
        <div className="flex items-center justify-between pt-6 pb-4 pr-2">
          <Line
            ref={temperatureChartRef}
            options={options}
            data={temperatureData}
            className="pr-2"
          />
        </div>
      </div>
      <div className="chart-container">
        <div className="flex items-center justify-between pt-14 pb-4">
          <div className="space-y-1">
            <h2 className="text-2xl font-semibold tracking-tight">
              Wind speed
            </h2>
            <p className="text-xs text-muted-foreground">
              Mean and maximum wind speed 10 meter above ground on a day.
              Simulations of winds and pressure systems in climate models are
              greatly influenced by the resolution used to model the terrain.
              Without bias correction, wind speed can vary significantly between
              different climate models, particularly in complex terrain.
              Although, data is bias corrected with ERA5, it might not
              accurately represent local conditions.
            </p>
          </div>
          <Button
            variant="secondary"
            type="button"
            className="text-xs ml-4"
            onClick={() => resetZoom(windSpeedChartRef)}
          >
            <ZoomOutIcon className="mr-2 h-4 w-4" />
            reset zoom
          </Button>
        </div>
        <div className="flex items-center justify-between pt-6 pb-4 pr-2">
          <Line
            ref={windSpeedChartRef}
            options={options}
            data={windSpeedData}
            className="pr-2"
          />
        </div>
      </div>
      <div className="chart-container">
        <div className="flex items-center justify-between pt-14 pb-4">
          <div className="space-y-1">
            <h2 className="text-2xl font-semibold tracking-tight">
              Precipitation
            </h2>
            <p className="text-xs text-muted-foreground">
              Sum of daily precipitation (including rain, showers and snowfall).
              Climate models have been able to capture some of the large-scale
              patterns of precipitation and associated droughts and extreme
              precipitation events, particularly over longer time scales.
              However, there are still uncertainties associated with the
              representation of precipitation at smaller geographical scales
              including thunderstorm. Please compare different climate models
              for drought duration or extreme precipitation events. The
              following papers analyze{" "}
              <AnimatedUnderline>
                <a
                  href="https://agupubs.onlinelibrary.wiley.com/doi/full/10.1029/2021EF002196"
                  target="_blank"
                >
                  extreme precipitation
                </a>
              </AnimatedUnderline>{" "}
              and{" "}
              <AnimatedUnderline>
                <a
                  href="https://agupubs.onlinelibrary.wiley.com/doi/full/10.1029/2021EF002150"
                  target="_blank"
                >
                  droughts in CMIP6 models
                </a>
              </AnimatedUnderline>
              .
            </p>
          </div>
          <Button
            variant="secondary"
            type="button"
            className="text-xs ml-4"
            onClick={() => resetZoom(precipitationChartRef)}
          >
            <ZoomOutIcon className="mr-2 h-4 w-4" />
            reset zoom
          </Button>
        </div>
        <div className="flex items-center justify-between pt-6 pb-4 pr-2">
          <Line
            ref={precipitationChartRef}
            options={options}
            data={precipitationData}
            className="pr-2"
          />
        </div>
      </div>
    </>
  );
}
