"use client";

import { Button } from "@/components/ui/button";
import { Icons } from "@/components/ui/icons";
import { useEvents } from "@/context/events-context";
import { firebaseFunction } from "@/firebase/data/util";
import {
  signInUser,
  verifyPhoneNumber,
  verifyTwoFactorCode,
} from "@/firebase/firebase";
import { getFirebaseConfig } from "@/firebase/firebase-config";
import Events from "@/firebase/logs/events";
import { cn } from "@/lib/utils";
import { Label } from "@radix-ui/react-dropdown-menu";
import { initializeApp } from "firebase/app"; // Import firebase
import "firebase/auth"; // Import firebase auth
import { RecaptchaVerifier, getAuth } from "firebase/auth";
import * as React from "react";
import { useNavigate } from "react-router-dom";
export const resetPassword = async (email: string) => {
  const resetUserPassword = firebaseFunction("resetUserPassword");
  await resetUserPassword({ email });
};

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

const defaultFormFields = {
  email: "",
  password: "",
  verificationCode: "",
};

export function UserAuthForm({ className, ...props }: UserAuthFormProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [formFields, setFormFields] = React.useState(defaultFormFields);
  const { email, password, verificationCode } = formFields;
  const [error, setError] = React.useState<string>("");
  const [multiFactorResolver, setMultiFactorResolver] =
    React.useState<any>(null);
  const [verificationId, setVerificationId] = React.useState<string>("");
  const [recaptchaVerifier, setRecaptchaVerifier] = React.useState<any>(null);
  const [isResetFormVisible, setIsResetFormVisible] =
    React.useState<boolean>(false);
  const { events, setEvents } = useEvents();
  const navigate = useNavigate();

  const config = getFirebaseConfig();
  const firebaseApp = initializeApp(config);
  const auth = getAuth(firebaseApp);

  React.useEffect(() => {
    if (!recaptchaVerifier) {
      const verifier = new RecaptchaVerifier(auth, "mfa-captcha", {
        size: "invisible",
      });
      setRecaptchaVerifier(verifier);
    }
  }, [recaptchaVerifier]);

  const resetFormFields = () => {
    return setFormFields(defaultFormFields);
  };

  const handleSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    setIsLoading(true);
    setError("");

    try {
      const result = await signInUser(email, password);

      if ("multiFactorRequired" in result && result.multiFactorRequired) {
        const verificationId = await verifyPhoneNumber(
          result.resolver,
          recaptchaVerifier
        );
        setVerificationId(verificationId);
        setMultiFactorResolver(result.resolver);
      } else if ("user" in result) {
        setEvents(new Events(result.user.uid));
        events.login();
        resetFormFields();
        navigate("/dashboard");
      }
    } catch (error: any) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerificationSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    setIsLoading(true);
    setError("");

    try {
      const userCredential = await verifyTwoFactorCode(
        verificationId,
        verificationCode,
        multiFactorResolver
      );
      if (userCredential) {
        setEvents(new Events(userCredential.user.uid));
        events.login();
        resetFormFields();
        navigate("/dashboard");
      }
    } catch (error: any) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setFormFields({ ...formFields, [id]: value });
  };

  const handleError = (error: any) => {
    if (error.code === "auth/multi-factor-auth-required") {
      setError("Multi-factor authentication is not available on ClimaCast.");
    } else if (
      [
        "auth/user-not-found",
        "auth/wrong-password",
        "auth/invalid-email",
      ].includes(error?.code)
    ) {
      setError("Incorrect email or password.");
    } else {
      console.error(error);
      setError(
        "Login failed, please try again or contact support@ehab.co if the error persists."
      );
    }
  };

  const handleResendCode = async () => {
    setIsLoading(true);
    setError("");

    try {
      if (multiFactorResolver) {
        const verificationId = await verifyPhoneNumber(
          multiFactorResolver,
          recaptchaVerifier
        );
        setVerificationId(verificationId);
      }
    } catch (error: any) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleResetFormVisibility = () => {
    setIsResetFormVisible(!isResetFormVisible);
  };

  return (
    <>
      <div id="mfa-captcha"></div>
      <div className={cn("grid gap-6", className)} {...props}>
        {!multiFactorResolver ? (
          <>
            {!isResetFormVisible ? (
              <form onSubmit={handleSubmit}>
                <div className="grid gap-2">
                  <div className="grid gap-1">
                    <Label className="sr-only">Email</Label>
                    <input
                      id="email"
                      placeholder="name@example.com"
                      type="email"
                      autoCapitalize="none"
                      autoComplete="email"
                      autoCorrect="off"
                      value={email}
                      onChange={handleChange}
                      disabled={isLoading}
                      required
                      className="self-stretch text-s h-9 p-2 rounded-md border border-input"
                    />
                  </div>
                  <div className="grid gap-1">
                    <Label className="sr-only">Password</Label>
                    <input
                      id="password"
                      type="password"
                      placeholder="password"
                      autoCapitalize="none"
                      autoComplete="password"
                      autoCorrect="off"
                      value={password}
                      onChange={handleChange}
                      disabled={isLoading}
                      required
                      className="text-s h-9 p-2 rounded-md border border-input"
                    />
                  </div>
                  <Button disabled={isLoading}>
                    {isLoading && (
                      <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    Sign In
                  </Button>
                  <div className="text-center mt-2">
                    <button
                      type="button"
                      onClick={toggleResetFormVisibility}
                      className="text-sm text-blue-500"
                    >
                      Forgot Password?
                    </button>
                  </div>
                </div>
                {error && (
                  <div className="text-xs text-red-500 mt-4">{error}</div>
                )}
              </form>
            ) : (
              <PasswordResetForm onBack={toggleResetFormVisibility} />
            )}
          </>
        ) : (
          <form onSubmit={handleVerificationSubmit}>
            <div className="grid gap-2">
              <div className="grid gap-1">
                <Label className="sr-only">Verification Code</Label>
                <input
                  id="verificationCode"
                  placeholder="Enter 2FA Code"
                  type="text"
                  autoCapitalize="none"
                  autoComplete="one-time-code"
                  autoCorrect="off"
                  value={verificationCode}
                  onChange={handleChange}
                  disabled={isLoading}
                  required
                  className="text-s h-9 p-2 rounded-md border border-input"
                />
              </div>
              <Button disabled={isLoading}>
                {isLoading && (
                  <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
                )}
                Verify
              </Button>
              <div className="text-center mt-2">
                <button
                  type="button"
                  onClick={handleResendCode}
                  className="text-sm text-blue-500"
                >
                  Resend Code
                </button>
              </div>
            </div>
            {error && <div className="text-xs text-red-500 mt-4">{error}</div>}
          </form>
        )}
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <span className="w-full border-t" />
          </div>
          <div className="relative flex justify-center text-xs uppercase">
            <span className="bg-background px-2 text-muted-foreground">OR</span>
          </div>
        </div>
        <Button
          variant="outline"
          type="button"
          disabled={isLoading}
          onClick={() => window.open("https://app.ehab.co/register", "_blank")}
        >
          {isLoading ? (
            <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
          ) : (
            ""
          )}
          Create a free account
        </Button>
        <div id="recaptcha-container"></div>
      </div>
    </>
  );
}

// Password Reset Form Component
const PasswordResetForm = ({ onBack }: { onBack: () => void }) => {
  const { email, error, success, isLoading, handleChange, handleSubmit } =
    usePasswordReset();

  return (
    <form
      onSubmit={handleSubmit}
      
      aria-label="Reset Password"
    >
      <div className="grid gap-2">
        <div className="grid gap-1">
          <Label className="sr-only">Email</Label>
          <input
            className="self-stretch text-s h-9 p-2 rounded-md border border-input"
            value={email}
            onChange={handleChange}
            type="email"
            placeholder="Email"
            autoComplete="email"
            name="email"
            disabled={isLoading}
            required
          />
        </div>
        {error && <p className="pt-4 text-red-700 text-center">{error}</p>}
        {success && (
          <p className="pt-4 text-xs text-emerald-600 text-center">{success}</p>
        )}
        
        <Button className="my-4" type="submit" disabled={isLoading}>
          {isLoading && (
            <div className="py-4">
              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
            </div>
          )}
          Send password reset email
        </Button>
        <button
          type="button"
          onClick={onBack}
          className="text-sm text-blue-500"
        >
          Back to Login
        </button>
      </div>
    </form>
  );
};

const usePasswordReset = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const [email, setEmail] = React.useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSuccess("");
    setError("");
    setEmail(e.target.value);
  };

  const isValid = () => {
    return email !== "";
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSuccess("");
    setError("");
    if (!isValid()) {
      setError("Please provide the email address for your account");
      return;
    }
    setIsLoading(true);
    try {
      await resetPassword(email);
      setIsLoading(false);
      setSuccess(
        `Password reset email sent to "${email}"`
      );
    } catch (error) {
      console.error(error);
      setError(
        "Password reset failed, please try again or contact support@ehab.co if the error persists."
      );
      setIsLoading(false);
    }
  };

  return { email, isLoading, error, success, handleChange, handleSubmit };
};

export default UserAuthForm;
