import { useEvents } from "@/context/events-context";
import { loadRules, saveRules } from "@/firebase/data/rules";
import { db } from "@/firebase/firebase";
import { IRules } from "@/lib/types";
import { ISite } from "@ehabitation/ts-utils/browser";
import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useRef } from "react";

export const useSite = (
  currentUser: any,
  setSite: React.Dispatch<React.SetStateAction<ISite | undefined>>,
  setRules: React.Dispatch<React.SetStateAction<IRules>>,
  site?: ISite
) => {
  const prevSiteId = useRef("");

  useEffect(() => {
    if (!site?.id) {
      console.log("!site?.id: ", site);
      return;
    }

    // Prevent unnecessary re-subscription if the site id hasn't changed
    if (site.id === prevSiteId.current) {
      return;
    }
    prevSiteId.current = site.id;

    const siteRef = doc(db, "sites", site.id);
    const unsubscribe = onSnapshot(
      siteRef,
      async (docSnapshot) => {
        console.log("Reset rules");
        setRules({}); // Clearing rules state to prepare for new data

        if (docSnapshot.exists()) {
          const updatedSite = {
            id: docSnapshot.id,
            ...docSnapshot.data(),
            creationDate: docSnapshot.data().creationDate.toDate(),
          } as ISite;
          console.log("SITE UPDATED! ", updatedSite);
          setSite(updatedSite);

          try {
            const dbRules = await loadRules(site.id, currentUser.uid);
            setRules(dbRules);
            console.log("dbRules: ", dbRules);
          } catch (error) {
            console.error("Error initializing site rules:", error);
          }
        } else {
          console.log("No such document!");
        }
      },
      (error) => {
        console.error("Error fetching site document:", error);
      }
    );

    // Cleanup function to unsubscribe from the listener
    return () => unsubscribe();
  }, [site?.id]);
};

// export default useSite;

export const useSaveRules = (rules: IRules, currentUser: any, site?: ISite) => {
  const prevRulesRef = useRef<IRules | undefined>();
  const {events} = useEvents()

  useEffect(() => {
    if (rules && site && prevRulesRef.current) {
      console.log("saveRules: ", rules);
      saveRules(prevRulesRef.current, rules, site.id, currentUser.uid)
        .then((newRuledIds) => {
          for(const ruleId of newRuledIds) {
            events.new_rule(site!.id, ruleId)
          }
          console.log("Rules saved successfully");
        })
        .catch((error: any) => console.error("Failed to save rules", error));
    } else {
      console.log(
        "No previous rules to compare with, potentially handling first save differently"
      );
    }
    prevRulesRef.current = rules;
  }, [rules]);
};