import { IRules } from "@/lib/types";
import { ISite } from "@ehabitation/ts-utils/browser";
import { Pencil1Icon } from "@radix-ui/react-icons";
import React from "react"; // Import React

interface SiteSummaryCardProps extends React.HTMLAttributes<HTMLDivElement> {
  site?: ISite;
  setSite: React.Dispatch<React.SetStateAction<ISite | undefined>>;
  setDisplayLocationSearch: React.Dispatch<React.SetStateAction<boolean>>;
  rules?: IRules;
}

export function SiteSummary({
  site,
  setSite,
  setDisplayLocationSearch,
  rules,
}: SiteSummaryCardProps) {
  const title = site!.name; // TODO TEMPORARY
  const latLong = `Lat: ${site!.location.latitude}, Lng: ${
    site!.location.longitude
  }`;

  const getMiniDesc = (site: ISite, rules?: IRules): string => {
    const { address, name } = site;
    let riskStatement = "";
    if (rules) {
      // Build the risk analysis part
      riskStatement = "on " + Object.keys(rules).join(", ");
    }

    // Build the location and time coverage part
    const dataStartYear: string = "1979",
      dataEndYear: string = "2024";
    const timeCoverage = `${dataStartYear} to ${dataEndYear}`;
    const locationAndTime = `Historical data from ${timeCoverage}. Climate data from 1950 to 2049`;

    // Construct the final summary
    const summary = `Analysing weather risks ${riskStatement} at ${name}. ${locationAndTime}.`;

    return summary;
  };

  const handleEditClick = () => {
    // TODO this should display the from, not necessarily reset the site...
    setDisplayLocationSearch(true); // Update locationId to undefined
    setSite(undefined);
  };

  return (
    <>
      <div className="flex justify-between items-center mb-2 px-4">
        <span className="text-lg font-semibold tracking-tight">{title}</span>
        <Pencil1Icon
          onClick={handleEditClick}
          className="cursor-pointer"
        />{" "}
      </div>
      <p className="px-4 text-xs text-muted-foreground pb-4">{latLong}</p>

      {site?.status == "open" && (
        <>
          <p className="px-4 text-sm text-muted-foreground pb-4">
            {getMiniDesc(site, rules)}
          </p>
          <div className="px-4 flex justify-between space-x-4 text-xs text-muted-foreground">
            <div className="flex items-center">{/* High Risk */}</div>
            <div>Created {site.creationDate.toLocaleDateString()}</div>
          </div>
        </>
      )}
    </>
  );
}
