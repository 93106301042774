import { IRules, IRule } from "@/lib/types";
import { IRiskMatrix } from "@ehabitation/ts-utils/browser";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { ListRuleFactors } from "./list-rule-factors";
import { NewFactorForm } from "./new-factor-form";
import { deleteRule } from "@/firebase/data/rules";
import { useEvents } from "@/context/events-context";

interface RuleCardProps extends React.HTMLAttributes<HTMLDivElement> {
  rule?: IRule;
  rules: IRules;
  setRules: React.Dispatch<React.SetStateAction<IRules>>;
}

// TODO these need setting properly.
const FormSchema = z.object({
  rule_name: z.string().min(2, "Name must be at least 2 characters."),
});

export function RuleCard({ rule, rules, setRules }: RuleCardProps) {
  const [activeDialogRule, setActiveDialogRule] = useState<string | null>(null);
  const {events} = useEvents()

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      rule_name: "",
    },
  });

  const handleDeleteRule = () => {
    events.delete_rule(rule?.siteId!, rule?.id!)
    setRules((currentRiskMatrix) => {
      const updatedRiskMatrix = { ...currentRiskMatrix };
      delete updatedRiskMatrix[activeDialogRule!];
      return updatedRiskMatrix;
    });
    setActiveDialogRule(null);
  };

  return (
    <>
      <div className="flex flex-col w-full items-start gap-2 p-4 pt-0">
        <div className="flex w-full flex-col gap-1">
          {rule && (
            <div key={rule.name}>
              <div className="flex justify-between w-full">
                <h2 className="font-bold">
                  {rule?.name} ({rule?.shift})
                </h2>
                <Dialog>
                  <DialogTrigger asChild>
                    <button
                      onClick={() => {
                        setActiveDialogRule(rule?.name ?? null);
                      }}
                      className="px-2 py-1 text-xs text-black-400 rounded hover:bg-red-100"
                    >
                      X
                    </button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogTitle>Delete Rule</DialogTitle>
                    <DialogDescription>
                      Are you sure you want to delete this rule? This action
                      cannot be undone.
                    </DialogDescription>
                    <div className="flex justify-end gap-2">
                      <DialogClose asChild>
                        <Button variant="ghost">Cancel</Button>
                      </DialogClose>
                      <Button variant="destructive" onClick={handleDeleteRule}>
                        Delete
                      </Button>
                    </div>
                  </DialogContent>
                </Dialog>
              </div>
              {/* TODO COULD THIS DIV BE REMOVED? */}
              <div className="flex w-full items-center">
                <ListRuleFactors
                  rule={rule}
                  rules={rules}
                  setRules={setRules}
                />
              </div>

              {rule && <NewFactorForm rule={rule} setRules={setRules} />}
            </div>
          )}
        </div>

        {/* <div className="line-clamp-2 text-xs text-muted-foreground">
          {"Item description.".substring(0, 300)}
        </div> */}
      </div>
    </>
  );
}
