// Import React and necessary hooks
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { getClimateHeatmap } from "@/firebase/data/heatmap";
import { IClimateHeatmapData, IRules } from "@/lib/types";
import { ISite } from "@ehabitation/ts-utils/browser";
import React, { useEffect, useMemo, useState } from "react";
import { MultiRowsHeatmap } from "./multirows-heatmap";
import AnimatedUnderline from "@/components/ui/animations/underline-animation";
import { useEvents } from "@/context/events-context";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

interface ClimateHeatmapSectionProps
  extends React.HTMLAttributes<HTMLDivElement> {
  site?: ISite;
  rules?: IRules;
}

const ClimateHeatmapSection: React.FC<ClimateHeatmapSectionProps> = ({
  site,
  rules,
}) => {
  const [heatmap, setHeatmap] = useState<IClimateHeatmapData>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { events } = useEvents();

  useEffect(() => {
    if (site) events.climate_view(site.id);
  }, []);

  useEffect(() => {
    if (
      site &&
      site.status === "open" &&
      rules &&
      Object.entries(rules).length > 0
    ) {
      setIsLoading(true); // Set loading to true at the beginning of the operation
      getClimateHeatmap(site.id, rules).then(
        (heatmapData: IClimateHeatmapData | null) => {
          if (rules && Object.entries(rules).length > 0 && heatmapData) {
            setHeatmap(heatmapData);
          } else {
            setHeatmap({});
          }
          setIsLoading(false); // Set loading to false only after the operation completes
        }
      );
    } else {
      setHeatmap({});
      setIsLoading(false); // Ensure loading is set to false if the condition to fetch data is not met
    }
  }, [site, rules]);

  // Memoize the rendering of the Accordion only if there's data
  const memoizedAccordion = useMemo(() => {
    if (heatmap && Object.keys(heatmap).length > 0) {
      return (
        <>
          <Accordion type="multiple" className="w-full">
            {" "}
            {/* Adjust to 3 columns on medium screens */}
            {Object.entries(heatmap).map(
              ([categoryName, categoryData], index) => (
                <div key={index} className="col-span-1">
                  {" "}
                  {/* Each item takes up one column */}
                  <AccordionItem value={categoryName}>
                    <AccordionTrigger>
                      <div className="flex flex-col w-full">
                        {" "}
                        <div className="w-full rounded-lg px-4 text-left">
                          {" "}
                          <div className="space-y-1">
                            <h4 className="text-lg font-semibold tracking-tight">
                              {categoryName}
                            </h4>
                            <p className="text-xs text-muted-foreground w-full">
                              {" "}
                              {/* TODO generate a summary description in the heatmap
                              Auto generate summary of impacts... */}
                            </p>
                          </div>
                        </div>
                        <MultiRowsHeatmap
                          data={categoryData.combined_success_ratio}
                          title={"All factors combined"}
                        />
                      </div>
                    </AccordionTrigger>
                    <AccordionContent>
                      <div className="pl-4">
                        {Object.entries(categoryData.factors_success_ratio).map(
                          ([factorName, successRatios], factorIndex) => (
                            <MultiRowsHeatmap
                              key={factorIndex}
                              data={successRatios}
                              title={`${factorName}`}
                            />
                          )
                        )}
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                </div>
              )
            )}
          </Accordion>
        </>
      );
    }
  }, [heatmap]);

  return (
    <>
      <div className="flex flex-col items-center w-full gap-6 rounded-lg pb-4">
        <div className="w-full text-xs text-muted-foreground">
          {/* <h2 className="text-2xl font-semibold tracking-tight">
            Probability of Impact
          </h2> */}
          <h6 className="pb-2">
            Climate model:{" "}
            <AnimatedUnderline>
              <a
                href="https://www.wdc-climate.de/ui/cmip6?input=CMIP6.HighResMIP.EC-Earth-Consortium.EC-Earth3P-HR"
                target="_blank"
              >
                EC-Earth3P-HR, by the EC‐Earth consortium
              </a>
            </AnimatedUnderline>
          </h6>
          <p className="pt-2">
            Based on historical data and predefined rules, identify potential
            trends of potential impact.
          </p>
          <p className="pt-2">
            The model provides daily values for mean air temperature at 2 meters
            above ground, total daily precipitation and mean wind speeds at 10,
            which are then averaged into monthly values before applying the
            rules.
          </p>
          <p className="pt-2">
            The analysis utilises the EC-Earth3P-HR dataset from the EC‐Earth
            consortium. While climate models are inherently imperfect and
            subject to uncertainties and biases, they can still provide valuable
            insights. Temperature anomalies over extended periods are indicative
            of future climate trends. This paper examines the robustness of
            CMIP6 temperature predictions.
          </p>
          <p className="pt-2">
            Total daily precipitation includes rain, showers, and snowfall.
            Climate models successfully capture large-scale precipitation
            patterns and related events, such as droughts and extreme
            precipitation, especially over long durations. However,
            uncertainties remain in representing precipitation on smaller
            scales, such as thunderstorms.
          </p>
          <p className="pt-2">
            Mean wind speeds at 10 meters above ground level is also considered.
            Wind and pressure system simulations in climate models are highly
            dependent on the terrain resolution. Despite bias correction using
            ERA5 data, wind speeds can still vary significantly across different
            models, particularly in complex terrains, and may not fully reflect
            local conditions.
          </p>
        </div>
        {memoizedAccordion}
      </div>
      <div className="pb-4 pt-4">
        {isLoading && (
          <div className="flex items-left px-4 text-sm text-muted-foreground pb-4">
            <div className="pb-4">
              <Alert>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 animate-spin mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path d="M21 12a9 9 0 1 1-6.219-8.56" />
                </svg>
                <AlertTitle>
                  Loading data for heatmaps. It may take a few seconds.
                </AlertTitle>
                <AlertDescription className="text-xs pt-2">
                  100 years of climate data modeling from January 1950 to 2050.
                  The model uses both real historical data for initialization
                  and validation, and simulated past data to understand
                  long-term climate trends and improve model accuracy.
                </AlertDescription>
              </Alert>
            </div>
          </div>
        )}
        {rules && Object.entries(rules).length === 0 && (
          <p className="text-sm text-muted-foreground pt-4">
            Create at least one rule to visualise monthly heatmaps.
          </p>
        )}
      </div>
    </>
  );
};

export default ClimateHeatmapSection;
