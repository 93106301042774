import { IRules, IRule } from "@/lib/types";
import _ from "lodash";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { useEvents } from "@/context/events-context";

interface ListRuleFactorsProps extends React.HTMLAttributes<HTMLDivElement> {
  rule?: IRule;
  rules: IRules;
  setRules: React.Dispatch<React.SetStateAction<IRules>>;
}

export function ListRuleFactors({
  rule,
  rules,
  setRules,
}: ListRuleFactorsProps) {
  const form = useForm();
  const {events} = useEvents()

  const handleUpdateFactor = _.debounce((fieldName, value) => {
    const [ruleName, factorIndex, attribute] = fieldName.split("|");
    setRules((currentRiskMatrix) => {
      const updatedRiskMatrix = { ...currentRiskMatrix };

      if (updatedRiskMatrix[ruleName]) {
        const factorsUpdated = [...updatedRiskMatrix[ruleName].factors];

        if (factorIndex >= 0 && factorIndex < factorsUpdated.length) {
          const updatedFactor = {
            ...factorsUpdated[factorIndex],
            [attribute]: value,
          };
          factorsUpdated[factorIndex] = updatedFactor;
          updatedRiskMatrix[ruleName].factors = factorsUpdated;
        }
      }
      console.log("updatedRiskMatrix: ", updatedRiskMatrix);
      return updatedRiskMatrix;
    });
  }, 500); // TODO Do we want to have any lag here?? Not sure we need it anymore...

  const deleteFactor = (ruleName: string, factorId: string | number) => {
    events.delete_factor(rule?.siteId!, factorId.toString())
    setRules((currentRiskMatrix) => {
      const updatedRiskMatrix = { ...currentRiskMatrix };
      const updatedFactors = updatedRiskMatrix[ruleName].factors.filter(
        (factor) => factor.id !== factorId
      );
      updatedRiskMatrix[ruleName].factors = updatedFactors;
      return updatedRiskMatrix;
    });
  };

  const onSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault(); // This will prevent the default form submission behavior
    // Place your logic for what should happen when the form gets "submitted"
  };

  return (
    <>
    <div className="flex w-full flex-col items-bottom gap-2">
      <Form key="list-rule-factors" {...form}>
        <form onSubmit={onSubmit}>
          {rule &&
            rules[rule.name]?.factors.map((factor, index) => (
              <div key={factor.id}>
                <div className="flex items-bottom gap-2">
                  <FormField
                    name={rule.name + "|" + index + "|factor"}
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel></FormLabel>
                        <FormControl>
                          <Select
                            onValueChange={(value) =>
                              handleUpdateFactor(
                                rule.name + "|" + index + "|factor",
                                value
                              )
                            }
                            defaultValue={factor.factor}
                          >
                            <SelectTrigger className="w-full">
                              <SelectValue placeholder="e.g. Temperature or wind" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="minTemp">
                                Minimal Temperature, avg (C)
                              </SelectItem>
                              <SelectItem value="maxTemp">
                                Maximum Temperature, avg (C)
                              </SelectItem>
                              <SelectItem value="wind">
                                Wind speed, avg (m/sec)
                              </SelectItem>
                              <SelectItem value="windGusts">
                                Wind gusts, max (m/sec)
                              </SelectItem>
                              <SelectItem value="hourlyRainAcc">
                                Precipitation, hourly avg (mm/Hour)
                              </SelectItem>
                              <SelectItem value="dailyRainAcc">
                                Precipitation, daily avg (mm/Day)
                              </SelectItem>
                              <SelectItem value="snowfall">
                                Snowfall, hourly avg(m/Day)
                              </SelectItem>
                              <SelectItem value="snowfall24Hour">
                                Snowfall, daily avg (mm/Day)
                              </SelectItem>
                              <SelectItem value="waveHeight">
                                Wave height (meters)
                              </SelectItem>
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="flex items-center justify-center w-[20px]">
                    {" "}
                    {/* Adjust container width as needed */}
                    <span className="text-center">{factor.factor == "minTemp" ? ">" : "<"}</span>
                  </div>
                  <FormField
                    name={rule.name + "|" + index + "|factor_value"}
                    // defaultValue={factor.factor_value}
                    render={({ field }) => (
                      <FormItem className="w-[60px]">
                        <FormLabel></FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            onChange={(e) =>
                              handleUpdateFactor(
                                rule.name + "|" + index + "|factor_value",
                                e.target.value
                              )
                            }
                            defaultValue={factor.factor_value}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <button
                  onClick={() => deleteFactor(rule.name, factor.id)}
                  className="px-2 py-1 text-xs text-black-400 rounded hover:bg-red-100"
                >
                  X
                </button>
                </div>
              </div>
            ))}
        </form>
      </Form>
      </div>
    </>
  );
}
