import { SiteSummary } from "@/components/sidebar/site-summary";

import { IRules } from "@/lib/types";
import { ISite } from "@ehabitation/ts-utils/browser";
import { User } from "firebase/auth";
import { useEffect, useState } from "react";
import { ThresholdSettings } from "../thresholds/threshold-settings";
import { SiteSelector } from "./site-selector";

interface SidebarProps extends React.HTMLAttributes<HTMLDivElement> {
  site?: ISite;
  setSite: React.Dispatch<React.SetStateAction<ISite | undefined>>;
  rules?: IRules;
  setRules: React.Dispatch<React.SetStateAction<IRules>>;
  user: User;
}

export function Sidebar({
  site,
  setSite,
  rules,
  setRules,
  user,
}: SidebarProps) {
  const [displayLocationSearch, setDisplayLocationSearch] =
    useState<boolean>(true);

  useEffect(() => {
    
    setDisplayLocationSearch(false);
  }, [site]);

  return (
    <div className="pb-12 h-screen">
      <div className="space-y-4 py-4">
        <div className="px-3 py-2">
          {!site || displayLocationSearch ? (
            <SiteSelector
              site={site}
              setSite={setSite}
              userId={user.uid}
              setDisplayLocationSearch={setDisplayLocationSearch}
            />
          ) : (
            <>
              {site && (
                <>
                  <SiteSummary
                    site={site}
                    setSite={setSite}
                    setDisplayLocationSearch={setDisplayLocationSearch}
                    rules={rules}
                  />
                  <div className="px-4 text-sm text-muted-foreground pt-4 pb-6">
                    {/* <Separator /> */}
                  </div>

                  {site?.status == "open" && rules && (
                    <ThresholdSettings rules={rules} setRules={setRules} />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
