import React from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { ISiteHeatmapData } from "@/lib/types";
import { SingleRowHeatmap } from "./singlerow-heatmap";
import { SingleRowWeatherDays } from "./singlerow-weather-days";

interface HeatmapSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  heatmap?: ISiteHeatmapData;
}

export function HeatmapSection({ heatmap }: HeatmapSectionProps) {
  return (
    <>
      <div className="items-start justify-center gap-6 rounded-lg pb-4">
        <div className="space-y-1">
          <h2 className="text-2xl font-semibold tracking-tight">
            Probability of impact
          </h2>
          <p className="text-sm text-muted-foreground">
            Based on historical data and predefined rules, accurately identify
            potential areas of impact for the year.
          </p>
        </div>
      </div>

      {heatmap && Object.entries(heatmap).length > 0 ? (
        <Accordion type="multiple" className="w-full">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {" "}
            {/* Adjust to 3 columns on medium screens */}
            {Object.entries(heatmap).map(
              ([categoryName, categoryData], index) => (
                <div key={index} className="col-span-1">
                  {" "}
                  {/* Each item takes up one column */}
                  <AccordionItem value={categoryName}>
                    <AccordionTrigger>
                      <div className="flex flex-col w-full">
                        {" "}
                        <div className="w-full rounded-lg px-4 text-left">
                          {" "}
                          <div className="space-y-1">
                            <h4 className="text-lg font-semibold tracking-tight">
                              {categoryName}
                            </h4>
                            <p className="text-xs text-muted-foreground w-full">
                              {" "}
                              {/* TODO uncomment this when we improve the summary description
                              {categoryData.summary} */}
                            </p>
                          </div>
                        </div>
                        <SingleRowHeatmap
                          data={categoryData.combined_success_ratio}
                          title={"All factors combined"}
                        />
                        <SingleRowWeatherDays data={categoryData.combined_success_ratio}
                        title="Weekdays impacted by weather / month"/>
                        
                      </div>
                    </AccordionTrigger>
                    <AccordionContent>
                      <div className="pl-4">
                        {Object.entries(categoryData.factors_success_ratio).map(
                          ([factorName, successRatios], factorIndex) => (
                            <div className="pb-4">
                            <SingleRowHeatmap
                              key={factorIndex}
                              data={successRatios}
                              title={`${factorName}`}
                            />
                            <SingleRowWeatherDays key={factorIndex + "weatherdays"} data={successRatios}
                        title="Weekdays impacted by weather / month"/>
                          </div>
                          )
                        )}
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                </div>
              )
            )}
          </div>
        </Accordion>
      ) : (
        <p className="text-sm text-muted-foreground">
          Create at least one rule to visualise monthly heatmaps.
        </p>
      )}
    </>
  );
}
