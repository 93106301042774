import { UserAuthForm } from "@/components/login/user-auth-form";
import AnimatedUnderline from "@/components/ui/animations/underline-animation";
import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";

const defaultFormFields = {
  email: "",
  password: "",
};

function Home() {
  return (
    <>
      <div className="container relative hidden h-screen flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
        <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
          <div className="absolute inset-0 bg-zinc-900" />
          <div className="relative z-20 flex items-center text-lg font-medium">
            <img src="/ehab-white-logo.svg" className="h-[30px]" />
          </div>
          <div className="relative z-20 mt-auto">
            <blockquote className="space-y-2">
              <p className="text-lg">
                &ldquo;Every region in the world is projected to face further
                increases in climate hazards.&rdquo;
              </p>
              <footer className="text-sm">
                Intergovernmental Panel on Climate Change (IPCC) - Report 2023
              </footer>
            </blockquote>
          </div>
        </div>
        <div className="lg:p-8">
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight">
                Sign into EHAB
              </h1>
              <p className="text-sm text-muted-foreground">
                {/* Enter your email below to sign in to ehab */}
              </p>
            </div>
            <UserAuthForm />
            <p className="px-8 text-center text-sm text-muted-foreground">
              By clicking continue, you agree to our{" "}
              <a
                href="https://drive.google.com/file/d/1W7lZ3wxJwJ3umICHpGlsF4fCgPMw0_cb/view?usp=sharing"
                target="_blank"
                className="underline underline-offset-4 hover:text-primary"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="https://ehab.co/privacy-policy/"
                target="_blank"
                className="underline underline-offset-4 hover:text-primary"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
