import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { AuthContext } from "@/context/auth-context";
import { useEvents } from "@/context/events-context";
import { createNewSite, fetchSite } from "@/firebase/data/site";
import { ISite } from "@ehabitation/ts-utils/browser";

import { useLoadScript } from "@react-google-maps/api";
import { GeoPoint } from "firebase/firestore";
import { useContext, useRef } from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

interface GoogleMapsFormProps extends React.HTMLAttributes<HTMLDivElement> {
  setSite: React.Dispatch<React.SetStateAction<ISite | undefined>>;
  setDisplayLocationSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

export function GoogleMapsForm({
  setSite,
  setDisplayLocationSearch,
}: GoogleMapsFormProps) {
  const { currentUser } = useContext(AuthContext);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_API_KEY!,
    libraries: ["places"],
  });

  if (!isLoaded) return <div>Loading...</div>;
  return (
    <>
      <div className="places-container text-sm text-muted-foreground">
        <PlacesAutocomplete
          setSite={setSite}
          user={currentUser}
          setDisplayLocationSearch={setDisplayLocationSearch}
        />
      </div>
    </>
  );
}

const PlacesAutocomplete = ({
  setSite,
  user,
  setDisplayLocationSearch,
}: any) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const last_lat = useRef<number | null>(null);
  const last_lng = useRef<number | null>(null);
  const {events} = useEvents()

  const handleSelect = async (address: string) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const formated_address = results[0].formatted_address;
    const { lat, lng } = await getLatLng(results[0]);

    if (lat !== last_lat.current || lng !== last_lng.current) {
      const userId = user.uid;
      const geoPointLocation = new GeoPoint(lat, lng);
  
      let newSiteId;
  
      try {
        const newSiteTyped: Partial<
          Pick<
            ISite,
            | "name"
            | "createdBy"
            | "location"
            | "address"
            | "creationDate"
            | "status"
            | "owner"
            | "orgId"
            | "deletedAt"
            | "deletedBy"
          >
        > = {
          name: formated_address,
          location: geoPointLocation,
          address: formated_address,
          createdBy: userId,
          creationDate: new Date(),
          deletedAt: null,
          deletedBy: null,
          status: "pending",
        };
        newSiteTyped.owner = userId;
  
        newSiteId = await createNewSite(userId, newSiteTyped);
        
      } catch (e) {
        const error = e as Error;
        console.error(error);
      }
  
      if (newSiteId) {
        const newSite = await fetchSite(newSiteId);
        console.log("newSite: ", newSite);
        events.new_site(newSiteId)
        setSite(newSite);
        last_lat.current = lat;
        last_lng.current = lng;
      }
    }

  };

  const handleClearInput = () => {
    setDisplayLocationSearch(false);
  };

  return (
    <>
      <Command>
        <div className="relative">
          {" "}
          {/* Wrapper to position the input and button */}
          <CommandInput
            value={value}
            onValueChange={(value) => {
              setValue(value);
            }}
            disabled={!ready}
            placeholder="Search an address to create a new site"
            className="combobox-input"
          />
          <button
            onClick={handleClearInput}
            className="absolute inset-y-0 right-0 flex items-center pr-3"
          >
            X {/* Customize this part as needed, consider using an icon */}
          </button>
        </div>
        <CommandList>
          <CommandGroup>
            {status === "OK" &&
              data.map(({ place_id, description }) => (
                <CommandItem
                  key={place_id}
                  value={description}
                  onSelect={handleSelect}
                >
                  <span>{description}</span>
                </CommandItem>
              ))}
          </CommandGroup>
        </CommandList>
      </Command>
    </>
  );
};
