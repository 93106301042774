import { IRules } from "@/lib/types";
import {
  FirestoreError,
  Timestamp,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
  writeBatch
} from "firebase/firestore";
import { db } from "../firebase";

export async function loadRules(
  siteId: string,
  ownerId: string
): Promise<{ [key: string]: any }> {
  // Adjusting the query to account for the composed document ID structure used in saveRules
  const rulesCollection = collection(db, "rules");
  const rulesQuery = query(
    rulesCollection,
    where("siteId", "==", siteId),
    where("owner", "==", ownerId)
  );

  try {
    const querySnapshot = await getDocs(rulesQuery);
    const rules: { [key: string]: any } = {};
    querySnapshot.forEach((doc) => {
      // Extract the rule name from the document ID if structured as siteId_userId_ruleName
      const parts = doc.id.split('_');
      const ruleName = parts.slice(2).join('_'); // Assuming ruleName might itself contain underscores
      const data = doc.data();
      rules[ruleName] = {
        ...data,
        factors: data.factors || [] // Assuming factors are embedded and defaulting to an empty array if not present
      };
    });
    return rules;
  } catch (error) {
    console.error("Error loading rules:", error);
    throw new Error(
      "Failed to load rules: " + (error instanceof FirestoreError ? error.message : 'unknown error')
    );
  }
}


export async function saveRules(
  previousRules: IRules,
  newRules: IRules,
  siteId: string,
  userId: string
): Promise<string[]> {
  const batch = writeBatch(db);
  const now = Timestamp.now();

  // Determine rules to delete
  const rulesToDelete = Object.keys(previousRules).filter(ruleName => !newRules[ruleName]);

  // Add delete operations to the batch
  rulesToDelete.forEach(ruleName => {
    const ruleId = `${siteId}_${userId}_${ruleName}`; // Constructing the document ID
    const ruleRef = doc(db, "rules", ruleId);
    batch.delete(ruleRef);
  });

  const savedRulesIds: string[] = []
  // Determine rules to add or update
  Object.entries(newRules).forEach(([ruleName, ruleData]) => {
    const ruleId = `${siteId}_${userId}_${ruleName}`; // Constructing the document ID
    const ruleRef = doc(db, "rules", ruleId);

    batch.set(ruleRef, {
      ...ruleData,
      siteId: siteId,
      owner: userId,
      createdAt: previousRules[ruleName] ? previousRules[ruleName].createdAt : now,
      updatedAt: now
    }, { merge: true });
    savedRulesIds.push(ruleId)
  });

  // Commit the batch
  try {
    await batch.commit();
    return savedRulesIds
    console.log("Rules updated and unused rules deleted successfully.");
  } catch (error) {
    console.error("Error updating and deleting rules:", error);
    throw new Error("Failed to update and delete rules: " + (error instanceof FirestoreError ? error.message : 'unknown error'));
  }
}

export async function deleteRule(siteId: string, ownerId: string, ruleName: string): Promise<void> {
  const ruleId = `${siteId}_${ownerId}_${ruleName}`; // Constructing the document ID
  const ruleRef = doc(db, "rules", ruleId);

  try {
      await deleteDoc(ruleRef);
      console.log("Rule successfully deleted, including all embedded factors");
  } catch (error) {
      console.error("Error deleting the rule:", error);
      throw new Error("Failed to delete the rule: " + (error instanceof FirestoreError ? error.message : 'unknown error'));
  }
}