// const nodeEnv = process.env.NODE_ENV;
// const commitHash = process.env.REACT_APP_COMMIT_HASH;

// const buildEnvironment = nodeEnv === "development" && !commitHash ? "local" : nodeEnv;

export const config = {
    appId: process.env.REACT_APP_APP_ID,
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    APPCHECK_E2E_OVERIDE: process.env.REACT_APP_APPCHECK_E2E_OVERIDE,
    RECAPTCHA_KEY: process.env.REACT_APP_RECAPTCHA_KEY,
    BACKEND_REGION: process.env.REACT_APP_BACKEND_REGION,
  };

  export function getFirebaseConfig() {
    if (!config || !config.apiKey) {
      throw new Error('No Firebase configuration object provided.' + '\n' +
      'Add your web app\'s configuration object to firebase-config.ts');
    } else {
      return config;
    }
  }    
  