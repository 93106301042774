import {
  ISite,
  parseDate,
  transformSiteDoc
} from "@ehabitation/ts-utils/browser";
import { collection, doc, getDoc, getDocs, orderBy, query, runTransaction, where } from "firebase/firestore";

import { db } from "@/firebase/firebase";

export const allSites = async (userId: string): Promise<ISite[]> => {
  try {
    const sitesCollection = collection(db, 'sites');
    const q = query(sitesCollection, where('owner', '==', userId), orderBy('creationDate', 'desc'));
    const querySnapshot = await getDocs(q);
    const sites: ISite[] = querySnapshot.docs.map(doc => ({
      ...(doc.data() as ISite),
      id: doc.id,
      creationDate: parseDate(doc.data().creationDate)!,
      deletedAt: parseDate(doc.data().deletedAt)!,
      startDate: parseDate(doc.data().startDate)!,
      endDate: parseDate(doc.data().endDate)!,
    }));
    return sites;
  } catch (error) {
    console.error("Error fetching user sites: ", error);
    throw error;
  }
};

export const createNewSite = async (userId: string, newSiteTyped: any) => {
  let newSiteId;
  await runTransaction(db, async (transaction) => {
    const newSiteDoc = doc(collection(db, "sites"));
    transaction.set(newSiteDoc, newSiteTyped);
    const siteUsersCollection = collection(newSiteDoc, "userPermissions");
    const siteUserDoc = doc(siteUsersCollection, userId);
    newSiteId = newSiteDoc.id; // "nPvufH4yYZOVTMKi4UBL" //newSiteDoc.id; // "bSXW9w0fVRuixjvG6O46" // "zPx1owE5oalx4U76XbsR"; // "nPvufH4yYZOVTMKi4UBL"  
    console.log("NEW SITE ID: ", newSiteId);
    transaction.set(siteUserDoc, {
      roles: ["owner", "admin", "editor", "viewer"],
    });
  });
  return newSiteId;
};

export const fetchSite = async (siteId: string): Promise<ISite | undefined> => {
  const sitesCollection = collection(db, "sites");
  const siteDocRef = doc(sitesCollection, siteId);

  try {
    const siteDocSnap = await getDoc(siteDocRef);
    if (siteDocSnap.exists()) {
      console.log("Site document data:", siteDocSnap.data());
      return transformSiteDoc(siteDocSnap.id, siteDocSnap.data());
    } else {
      console.log("No such site document!");
      return undefined;
    }
  } catch (error) {
    console.error("Error fetching site document:", error);
    return undefined;
  }
};

