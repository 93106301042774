import { ISiteHeatmapData } from "@/lib/types";
import { ISite } from "@ehabitation/ts-utils/browser";
import { LongtermWeatherCharts } from "./longterm-weather-charts";


interface LongtermMainProps extends React.HTMLAttributes<HTMLDivElement> {
  site?: ISite; // TODO define the type properly...
  siteHeatmap?: ISiteHeatmapData
}

export function LongtermMain({ site, siteHeatmap }: LongtermMainProps) {
  if (site === undefined) return <>No site selected.</>;
  return (
    <>
      <LongtermWeatherCharts site={site}/>
    </>
  );
}
