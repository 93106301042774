import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

import { Menu } from "@/components/dashboard/menu";
import { OverviewMain } from "@/components/overview/main";
import { Sidebar } from "@/components/sidebar/sidebar";

import { LongtermMain } from "@/components/longterm/main";
import { AuthContext } from "@/context/auth-context";
import useGetHeatmap from "@/hooks/heatmap";

import { useSaveRules, useSite } from "@/hooks/site";
import { IRules, ISiteHeatmapData } from "@/lib/types";
import { ISite } from "@ehabitation/ts-utils/browser";
import { ShareIcon } from "lucide-react";
import { useContext, useState } from "react";
import { SiteLoadStatus } from "@/components/overview/site-load-status";
import { IntroVideoSection } from "@/components/dashboard/intro-video-section";
import { useEvents } from "@/context/events-context";
import ClimateHeatmapSection from "@/components/overview/heatmap/climate-heatmap-section";

export default function Dashboard() {
  // TODO get these from the authenticated user
  const divisionId = ""; // filipe.moura is orgAdmin in dev; // xkwouL2wyDpIlb8UX6PZ : "NEW APP" division in development.ehab.co
  const projectId = ""; // filipe.moura is orgAdmin in dev;

  const [rules, setRules] = useState<IRules>({});
  const [site, setSite] = useState<ISite | undefined>();
  const [siteHeatmap, setSiteHeatmap] = useState<ISiteHeatmapData>();

  const { currentUser } = useContext(AuthContext);

  const {events} = useEvents()

  useSite(currentUser, setSite, setRules, site);
  useSaveRules(rules, currentUser, site);
  useGetHeatmap(rules, setSiteHeatmap, site);

  return (
    <>
      <div className="hidden md:block flex-wrap">
        <Menu />
        <div className="border-t">
          <div className="bg-background">
            <div className="grid lg:grid-cols-9">
              <div className="col-span-3 lg:col-span-3 pr-4">
                {" "}
                {/* Sidebar will occupy 1/4 of the space */}
                <Sidebar
                  site={site}
                  setSite={setSite}
                  rules={rules}
                  setRules={setRules}
                  user={currentUser!}
                />
              </div>
              <div className="col-span-6 lg:col-span-6 lg:border-l">
                <div className="h-full px-4 py-6 lg:px-8">
                  {!site && (
                    <>
                    <IntroVideoSection/>
                    </>
                  )}
                  {site?.status == "pending" && (
                    <>
                    <SiteLoadStatus site={site} />
                    </>
                  )}
                  {site?.status == "open" && (
                    <Tabs defaultValue="overview" className="h-full space-y-6">
                      <div className="space-between flex items-center">
                        <TabsList>
                          <TabsTrigger value="overview" className="relative">
                            Historical analysis
                          </TabsTrigger>
                          <TabsTrigger value="longterm">Climate modeling</TabsTrigger>
                          <TabsTrigger value="climate_heatmaps">
                            Climate Heatmaps
                          </TabsTrigger>
                        </TabsList>
                        <div className="ml-auto mr-4" hidden>
                          <Button
                            variant="ghost"
                            onClick={() => {
                              events.export(site.id)
                              window.print()
                            }}
                          >
                            <ShareIcon className="mr-2 h-4 w-4" />
                            Export
                          </Button>
                        </div>
                      </div>

                      <TabsContent
                        value="overview"
                        className="border-none p-0 outline-none"
                      >
                        <OverviewMain site={site} siteHeatmap={siteHeatmap} />
                      </TabsContent>

                      <TabsContent
                        value="longterm"
                        className="h-full flex-col border-none p-0 data-[state=active]:flex"
                      >
                        <LongtermMain site={site} />
                        <Separator className="my-4" />
                      </TabsContent>
                      
                      <TabsContent
                        value="climate_heatmaps"
                        className="h-full flex-col border-none p-0 data-[state=active]:flex"
                      >
                        <ClimateHeatmapSection site={site} rules={rules}/>
                        <Separator className="my-4" />
                      </TabsContent>
                    </Tabs>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
