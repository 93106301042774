import Events from "@/firebase/logs/events";
import { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./auth-context";

type EventsContextProps = {
    events: Events
    setEvents: React.Dispatch<React.SetStateAction<Events>>
}

const DEFAULT_VALUE: EventsContextProps = {
    events: new Events(),
    setEvents: () => {}
}

const EventsContext = createContext<EventsContextProps>(DEFAULT_VALUE)

export const EventsContextProvider = ({children}: {children: any}) => {
    const [instance, setInstance] = useState<Events>(DEFAULT_VALUE.events)
    const auth = useContext(AuthContext)
    useEffect(() => {
        if(auth.currentUser) {
            setInstance(new Events(auth.currentUser.uid))
        } else {
            setInstance(DEFAULT_VALUE.events)
        }
    }, [auth.currentUser])

    return <EventsContext.Provider value={{
        events: instance,
        setEvents: setInstance
    }}>
        {children}
    </EventsContext.Provider>
}

export const useEvents = () => useContext(EventsContext)