import { IRule, IRuleFactor, IRules } from "@/lib/types";
import { zodResolver } from "@hookform/resolvers/zod";
import _ from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import AnimatedUnderline from "../ui/animations/underline-animation";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Input } from "../ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { useEvents } from "@/context/events-context";

interface NewFactorFormProps extends React.HTMLAttributes<HTMLDivElement> {
  rule?: IRule;
  setRules: React.Dispatch<React.SetStateAction<IRules>>;
}

// TODO these need setting properly.
const FormSchema = z.object({
  factor: z.string().min(2, "select a weather factor"),
  factor_value: z.string().min(1, "Set a value as the threshold."),
});

export function NewFactorForm({ rule, setRules }: NewFactorFormProps) {
  const [displayForm, setDisplayForm] = useState<boolean>(false);
  const [comparison, setComparison] = useState("<");
  const {events} = useEvents()

  const newFactorForm = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      factor: "",
      factor_value: "",
    },
  });

  const handleNewFactor = _.debounce(async (formData) => {
    try {
      if (formData.factor == "minTemp") {
        setComparison(">");
      } else {
        setComparison("<");
      }
  
      const validatedData = await FormSchema.parseAsync(formData);
      const newFactor: IRuleFactor = {
        id: Date.now().toString(),
        factor: validatedData.factor,
        factor_value: validatedData.factor_value,
      };
  
      if (!rule) {
        // throw an error message. This should never be the case here
        return;
      }
  
      if (newFactor.factor !== "" && newFactor.factor_value !== "") {
        events.new_factor(rule.siteId!, newFactor.id);
        setRules((currentRiskMatrix) => {
          const ruleName = rule.name;
          const existingFactors = currentRiskMatrix[ruleName].factors;
          
          // Check if the new factor matches an existing factor
          const factorIndex = existingFactors.findIndex(f => f.factor === newFactor.factor);
          if (factorIndex > -1) {
            // Update existing factor with new values
            existingFactors[factorIndex] = {...existingFactors[factorIndex], ...newFactor};
          } else {
            // Add new factor as it doesn't exist
            existingFactors.push(newFactor);
          }
  
          // Update the rule with the modified list of factors
          return {
            ...currentRiskMatrix,
            [ruleName]: {
              ...currentRiskMatrix[ruleName],
              factors: existingFactors,
            },
          };
        });
        newFactorForm.reset();
      }
    } catch (error) {
      console.error("Validation Error:", error);
      // Handle validation failure
    }
  }, 500);
  

  const onSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault(); 
  };

  return (
    <>
      {!displayForm && (
        <div
          className="pt-3 text-muted-foreground text-xm"
          onClick={(e) => {
            setDisplayForm(true);
          }}
        >
          <AnimatedUnderline>Add a risk factor...</AnimatedUnderline>
        </div>
      )}
      {displayForm && (
        <div className="flex w-full flex-col items-bottom gap-2">
          <Form key="new-rule-form" {...newFactorForm}>
            <form onSubmit={onSubmit}>
              <div className="flex items-bottom gap-2">
                <FormField
                  control={newFactorForm.control}
                  name="factor"
                  render={({ field }) => (
                    <FormItem className="w-full">
                      <FormLabel></FormLabel>
                      <FormControl>
                        <Select
                          {...field}
                          name={field.name}
                          onValueChange={(value) => {
                            field.onChange(value); // First, ensure the form state is updated
                            // Next, call handleNewFactor with the updated form values
                            handleNewFactor({
                              ...newFactorForm.getValues(),
                              [field.name]: value,
                            });
                          }}
                        >
                          <SelectTrigger className="w-full">
                            <SelectValue placeholder="e.g. Temperature or wind" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="minTemp">
                              Minimal Temperature, avg (C)
                            </SelectItem>
                            <SelectItem value="maxTemp">
                              Maximum Temperature, avg (C)
                            </SelectItem>
                            <SelectItem value="wind">
                              Wind speed, avg (m/sec)
                            </SelectItem>
                            <SelectItem value="windGusts">
                              Wind gusts, max (m/sec)
                            </SelectItem>
                            <SelectItem value="hourlyRainAcc">
                              Precipitation, hourly avg (mm/Hour)
                            </SelectItem>
                            <SelectItem value="dailyRainAcc">
                              Precipitation, daily avg (mm/Day)
                            </SelectItem>
                            <SelectItem value="snowfall">
                              Snowfall, hourly avg(m/Day)
                            </SelectItem>
                            <SelectItem value="snowfall24Hour">
                              Snowfall, daily avg (mm/Day)
                            </SelectItem>
                            <SelectItem value="waveHeight">
                              Wave height (meters)
                            </SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                    </FormItem>
                  )}
                />
                <div className="flex items-center justify-center w-[20px]">
                  {" "}
                  <span className="text-center">{comparison}</span>
                </div>

                <FormField
                  control={newFactorForm.control}
                  name="factor_value"
                  render={({ field }) => (
                    <FormItem className="w-[60px]">
                      <FormLabel></FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            handleNewFactor({
                              ...newFactorForm.getValues(),
                              [field.name]: e.target.value,
                            });
                          }}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />

                <button
                  onClick={() => setDisplayForm(false)}
                  className="px-2 py-1 text-xs text-black-400 rounded hover:bg-red-100"
                >
                  X
                </button>
              </div>
            </form>
            <FormDescription>
              Add one or multiple thresholds. They will work in conjunction to
              best reflect the restrictions.
            </FormDescription>
          </Form>
        </div>
      )}
    </>
  );
}
