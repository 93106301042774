import { getHeatmap } from '@/firebase/data/heatmap';
import { IRules, ISiteHeatmapData } from '@/lib/types';
import { ISite } from '@ehabitation/ts-utils/browser';
import { useEffect } from 'react';

const useGetHeatmap = (rules: IRules, setSiteHeatmap: React.Dispatch<React.SetStateAction<ISiteHeatmapData | undefined>>, site?: ISite) => {
  useEffect(() => {
    if (site && site.status === "open" && rules) {
      getHeatmap(site.id, rules).then((heatmap: ISiteHeatmapData | null) => {
        if (rules && Object.entries(rules).length > 0 && heatmap) {
          setSiteHeatmap(heatmap);
        } else {
          setSiteHeatmap({});
        }
      });
    } else {
      setSiteHeatmap({});
    }
  }, [site, rules]);
};

export default useGetHeatmap;
