import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import moment from "moment";

interface SingleRowWeatherDaysProps {
  title: string;
  data: number[];
}

export function SingleRowWeatherDays({
  title,
  data,
}: SingleRowWeatherDaysProps) {
  const squareWidthPercent = 100 / data.length;

  const getWorkingDaysInMonth = (year: number, month: number): number => {
    let count = 0;
    const date = new Date(year, month, 1);
    while (date.getMonth() === month) {
      const day = date.getDay();
      if (day !== 0 && day !== 6) count++; // Exclude Sunday (0) and Saturday (6)
      date.setDate(date.getDate() + 1);
    }
    return count;
  };

  const getMonthLabel = (index: number) => {
    const monthLabels: Record<number, string> = {
      0: "Jan",
      1: "Feb",
      2: "Mar",
      3: "Apr",
      4: "May",
      5: "Jun",
      6: "Jul",
      7: "Aug",
      8: "Sep",
      9: "Oct",
      10: "Nov",
      11: "Dec",
    };
    return monthLabels[index] || ""; // Return the label or an empty string if not found
  };

  const currentYear = new Date().getFullYear();

  return (
    <>
      <div className="flex flex-col items-start gap-2 rounded-lg px-4 pt-2">
        <div className="flex w-full">
          {data.map((monthlyRate, i) => {
            const probOfFailure = 1 - monthlyRate;
            const workingDays = getWorkingDaysInMonth(currentYear, i);
            const expectedWeatherDays = Math.round(probOfFailure * workingDays);
            return (
              <>
              <TooltipProvider key={i}>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger asChild>
                    <div
                      style={{
                        width: `${squareWidthPercent}%`,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        marginRight: "2px",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          height: "14px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="text-[9px] bg-gray-200"
                      >
                        {expectedWeatherDays}
                      </div>
                      
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>
                      {expectedWeatherDays} expected weather days in{" "}
                      {getMonthLabel(i)} (excluding Saturday and Sunday)
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
              
              </>
            );
          })}
        </div>
        <div className="text-xs">{title}</div>
      </div>
    </>
  );
}
