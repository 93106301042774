import {logEvent, setUserId} from 'firebase/analytics'
import { analytics } from '../firebase'

class Events {

    constructor(userId?: string) {
        if(userId)
            setUserId(analytics, userId)
    }

    login(method = "password") {
        try {
            logEvent(analytics, "cc_login", {method});
            console.info('Loggin event registered')
        } catch (error) {
            console.error(error);
        }
    }

    new_site(siteId: string) {
        try {
            logEvent(analytics, 'cc_new_site', {siteId})
            console.info('New site event registered', siteId)
        } catch (error) {
            console.error(error);
        }
    }

    open_site(siteId: string) {
        try {
            logEvent(analytics, 'cc_open_site', {siteId})
            console.info('Open site event registered', siteId)
        } catch (error) {
            console.error(error);
        }
    }

    new_rule(siteId: string, ruleId: string) {
        try {
            logEvent(analytics, 'cc_new_rule', {siteId})
            console.info('New rule event registered', siteId, ruleId)
        } catch (error) {
            console.error(error);
        }
    }

    delete_rule(siteId: string, ruleId: string) {
        try {
            logEvent(analytics, 'cc_delete_rule', {siteId})
            console.info('Delete rule event registered', siteId, ruleId)
        } catch (error) {
            console.error(error);
        }
    }

    new_factor(siteId: string, factorId: string) {
        try {
            logEvent(analytics, 'cc_new_factor', {siteId})
            console.info('New factor event registered', siteId, factorId)
        } catch (error) {
            console.error(error);
        }
    }

    delete_factor(siteId: string, factorId: string) {
        try {
            logEvent(analytics, 'cc_delete_factor', {siteId})
            console.info('New factor event registered', siteId, factorId)
        } catch (error) {
            console.error(error);
        }
    }

    view_historic_section(siteId: string) {
        try {
            logEvent(analytics, 'cc_view_historic_section', {siteId})
            console.info('View historic factor event registered', siteId)
        } catch (error) {
            console.error(error);
        }
    }

    view_longterm_section(siteId: string) {
        try {
            logEvent(analytics, 'cc_view_longterm_section', {siteId})
            console.info('View longterm section event registered', siteId)
        } catch (error) {
            console.error(error);
        }
    }

    export(siteId: string) {
        try {
            logEvent(analytics, 'cc_export', {siteId})
            console.info('Export event registered', siteId)
        } catch (error) {
            console.error(error);
        }
    }

    climate_view(siteId: string) {
        try {
            logEvent(analytics, 'cc_climate_view', {siteId})
            console.info('Climate view registered', siteId)
        } catch (error) {
            console.error(error)
        }
    }
}

export default Events