import { IRule, IRules } from "@/lib/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage
} from "../ui/form";
import { Input } from "../ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

interface NewRuleCardProps extends React.HTMLAttributes<HTMLDivElement> {
  rule?: IRule;
  rules: IRules;
  setRules: React.Dispatch<React.SetStateAction<IRules>>;
}

// TODO these need setting properly.
const FormSchema = z.object({
  rule_name: z.string().min(2, "Name must be at least 2 characters."),
  shift: z.string(),
});

export function NewRuleCard({ rule, rules, setRules }: NewRuleCardProps) {
  
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      rule_name: "",
      shift: "24hr",
    },
  });

  const handleNewRule = (data: z.infer<typeof FormSchema>) => {
    const ruleName = data.rule_name;
    const shift = data.shift;
    console.log("DATA: ", data);
    console.log("ruleName, shift: ", ruleName, shift);

    if (rules[ruleName]) {
      alert(
        "Rule already exists. This will be a proper error message in the form."
      );
    }

    if (ruleName === "" || shift === "") {
      return;
    }

    setRules((currentRiskMatrix) => {
      return {
        ...currentRiskMatrix,
        [ruleName]: {
          name: ruleName,
          shift: shift,
          factors: [],
        },
      };
    });

    form.reset({
      rule_name: "",
      shift: "24hr",
    });
  };

  return (
    <>
      <div className="flex flex-col items-start gap-2 p-4 pt-2">
        <div>
          <p className="text-m font-semibold">Create a new rule</p>
        </div>
        <div className="flex min-w-full flex-row ">
          {" "}
          {!rule && (
            <Form {...form}>
              <form onSubmit={form.handleSubmit(handleNewRule)}>
                <div className="flex items-center gap-4 w-full">
                  <div className="w-full">
                    <FormField
                      control={form.control}
                      name="rule_name"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              placeholder="e.g. Concrete Pour, Storm Drain Limit, etc"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <div>
                    <FormField
                      control={form.control}
                      name="shift"
                      render={({ field }) => (
                        <FormItem className="w-[100px]">
                          <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              <SelectItem value="24hr" defaultChecked>
                                24 hrs
                              </SelectItem>
                              <SelectItem value="Day">
                                Day only (7am to 7pm)
                              </SelectItem>
                              <SelectItem value="Night">
                                Night only (7pm to 7am)
                              </SelectItem>
                            </SelectContent>
                          </Select>

                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div>
                    <Button type="submit">Add</Button>
                  </div>
                </div>
              </form>
            </Form>
          )}
        </div>
        <div>
          <p className="text-sm text-muted-foreground">
            Give your rule a name. Each rule may contain multiple weather
            factors. These will be combined to assess the probability of this
            rule being breached.
          </p>
        </div>
      </div>
    </>
  );
}
