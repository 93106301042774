export function IntroVideoSection() {
  return (
    <>
      <div className="flex justify-center w-full mt-2">
        <div className="flex flex-col items-start gap-6 rounded-lg pb-4">
          <div className="space-y-1">
            <h2 className="text-2xl font-semibold tracking-tight">
              Introducing EHAB's ClimaCast
            </h2>
            <div className="text-sm text-muted-foreground pt-4">
              <p className="pt-2">
                Welcome to ClimaCast, EHAB’s solution specialy designed to
                assess and forecast climate and weather impacts across the
                globe. <br />
                By using high-quality weather data and EHAB's expertise in
                climate risk analysis, ClimaCast offers clear insights that help
                industries manage the effects of weather on their operations.
              </p>
              <p className="pt-2">
                Whether it’s planning daily activities, improving maintenance
                schedules, or ensuring smooth business operations, ClimaCast
                provides the information you need to make well-informed
                decisions.
              </p>
              <p className="pt-2">
                Explore how ClimaCast can help you better understand and adapt
                to the weather’s influence on your business.
              </p>
              <p className="pt-2">
                Become climate resilient with EHAB's ClimaCast.
              </p>
            </div>
          </div>
          <div className="mt-4">
            <iframe
              className="rounded-xl"
              width="840"
              height="472"
              src="https://youtube.com/embed/PHPfISvNmKA?rel=0&amp;controls=0"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}
