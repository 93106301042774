import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { IClimateFactorsSuccessRatio } from "@/lib/types";

interface MultiRowsHeatmapProps {
  title: string;
  data: IClimateFactorsSuccessRatio;
}

export function MultiRowsHeatmap({ title, data }: MultiRowsHeatmapProps) {
  const monthLabels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthsWithData = Object.keys(data).sort(
    (a, b) => parseInt(a) - parseInt(b)
  );
  const displayMonths = monthsWithData.map(
    (month) => monthLabels[parseInt(month) - 1]
  );
  const years = Object.keys(data[monthsWithData[0]]).sort();
  const decadeYears = years.filter((year, index) => index % 10 === 0);

  const probabilityColors = [
    { color: "bg-red-600", text: "80% - 100%" },
    { color: "bg-red-200", text: "60% - 79%" },
    { color: "bg-yellow-400", text: "40% - 59%" },
    { color: "bg-yellow-200", text: "20% - 39%" },
    { color: "bg-green-400", text: "0% - 19%" },
    { color: "bg-gray-300", text: "N/A" },
  ];

  const getColorForSuccessRatio = (ratio: number): string => {
    if (ratio >= 0.8) return "bg-red-600";
    if (ratio >= 0.6) return "bg-red-200";
    if (ratio >= 0.4) return "bg-yellow-400";
    if (ratio >= 0.2) return "bg-yellow-200";
    if (ratio >= 0.0) return "bg-green-400";
    return "bg-gray-300";
  };

  const squareWidthClass = `flex-1 min-w-[8px]`;

  return (
    <TooltipProvider>
      <div className="w-full flex flex-col items-start gap-2 p-4">
        <div className="text-left text-sm font-medium">{title}</div>
        <div className="flex w-full items-center">
          <div className="w-12 shrink-0"></div>{" "}
          {/* Placeholder for month label space */}
          {decadeYears.map((year) => (
            <div key={year} className="flex-1 min-w-[8px] text-left text-xs">
              {year}
            </div>
          ))}
        </div>
        {monthsWithData.map((month, index) => (
          <div key={index} className="flex w-full items-center">
            <div className="w-12 shrink-0 text-right text-xs pr-4">
              {displayMonths[index]}
            </div>
            {years.map((year) => {
              const monthData = data[month];
              const value = monthData ? monthData[year] : 0;
              const probOfFailure = 1 - value;
              return (
                <Tooltip key={`${month}-${year}`} delayDuration={0}>
                  <TooltipTrigger asChild>
                    <div
                      key={`${month}-${year}`}
                      className={`${squareWidthClass} h-4 ${getColorForSuccessRatio(
                        probOfFailure
                      )} hover:border hover:border-blue-500`}
                    >
                      <div className="w-full h-full"></div>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent className="text-left">
                    <p>
                      {displayMonths[index]}, {year}
                    </p>
                    <p>{(probOfFailure * 100).toFixed(1)}% prob. of impact </p>
                  </TooltipContent>
                </Tooltip>
              );
            })}
          </div>
        ))}
        <div className="flex w-full justify-end gap-2 mt-4 text-[11px] font-light">
          Probability of impact 
          {probabilityColors.map(({ color, text }) => (
            <div key={color} className="flex items-center gap-1 pr-2">
              <div className={`w-4 h-4 ${color}`}></div>
              <div className="text-[10px] font-light">{text}</div>
            </div>
          ))}
        </div>
      </div>
    </TooltipProvider>
  );
}
