import { ISite } from "@ehabitation/ts-utils/browser";
import { ArrowDownIcon } from "@radix-ui/react-icons";
import {
  CalendarIcon,
  DatabaseIcon,
  LineChartIcon,
  MergeIcon
} from "lucide-react";
import React, { useEffect, useState } from "react";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert";
import { Progress } from "../ui/progress";

interface SiteLoadStatusProps extends React.HTMLAttributes<HTMLDivElement> {
  site?: ISite;
}

const stages = [
  {
    key: "create_sources",
    name: "Creating Data Sources",
    description:
      "ECMWF Reanalysis v5 (ERA5) is utilized for historical data, while EC-Earth3P-HR model for future climate projections. These resources are crucial for analyzing historical trends and forecasting future climatic conditions up to the year 2050.",
    icon: <DatabaseIcon className="h-4 w-4 mr-2" />,
  },
  {
    key: "load_sources",
    name: "Loading Data Sources",
    description:
      "ERA5 merges observations from weather stations, aircraft, buoys, radars, and satellites, supplemented by mathematical models to estimate missing weather readings. This reanalysis provides hourly estimates of atmospheric, land, and oceanic climate variables across a 31 km grid, extending up to 80 km in the atmosphere. The climatic EC-Earth3P-HR model provides an atmospheric resolution of around 40 km and an oceanic resolution of 0.25 degrees, supported by a network of 30 research institutes across 12 European countries.",
    icon: <ArrowDownIcon className="h-4 w-4 mr-2" />,
  },
  {
    key: "combine_sources",
    name: "Combining Data Sources",
    description:
      "ERA5 offers hourly records of global climate since January 1940, emphasizing data richness for the period from 1979 to December 2023 - EHAB utilises this period. EC-Earth3P-HR models 100 years, from 1950 to 2050.",
    icon: <MergeIcon className="h-4 w-4 mr-2" />,
  },
  {
    key: "generating_climate_files",
    name: "Preparing Climate Visualisation",
    description:
      "Interactive charts provide rich visualisations of daily temperature (mean, min and max), wind (mean and max) and a combined daily precipitation (including rain, showers and snowfall), allowing to better understand the climate and potential trends.",
    icon: <LineChartIcon className="h-4 w-4 mr-2" />,
  },
  {
    key: "generating_monthly_ranges",
    name: "Generating Monthly Ranges",
    description:
      'Historic data is aggregated to represent a "standard year" at the specific location, which helps in understanding the expected weather and preparing for future environmental conditions.',
    icon: <CalendarIcon className="h-4 w-4 mr-2" />,
  },
];

const progressIncrements: { [key: string]: number } = {
  create_sources: 0,
  load_sources: 10,
  combine_sources: 50,
  generating_climate_files: 80,
  generating_monthly_ranges: 90,
};

export function SiteLoadStatus({ site }: SiteLoadStatusProps) {
  const [completedSteps, setCompletedSteps] = useState<string[]>([]);
  const [progressPercent, setProgressPercent] = useState(0);

  useEffect(() => {
    if (site && site.onboardingProgressStep) {
      console.log("NEW STEP: ", site.onboardingProgressStep, site.onboardingProgressMessage);
      setCompletedSteps([...completedSteps, site.onboardingProgressMessage!]);
      setProgressPercent(progressIncrements[site.onboardingProgressMessage!]);
    }
  }, [site!.onboardingProgressStep]);

  return (
    <>
      <div className="pb-4">
        <div className="flex items-center px-4 text-sm text-muted-foreground pb-4">
          <div className="flex justify-center items-center flex-none w-8 h-8">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="animate-spin"
            >
              <path d="M21 12a9 9 0 1 1-6.219-8.56" />
            </svg>
          </div>
          <div className="flex-1 ml-4">
            Loading data for {site!.name}. <br />
            This may take a couple of minutes. The page will refresh
            automatically.
          </div>
        </div>
        <div className="pt-2 pb-4">
          <Progress value={progressPercent} />
        </div>
      </div>

      {stages.map((stage, index) => (
        <div className="pb-4" key={index}>
          <Alert className={completedSteps.includes(stage.key) ? "" : "opacity-50"}>
            {index === completedSteps.length - 1 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 animate-spin mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path d="M21 12a9 9 0 1 1-6.219-8.56" />
              </svg>
            ) : (
              stage.icon
            )}
            <AlertTitle>{stage.name}</AlertTitle>
            <AlertDescription className="text-xs pt-2">
              {stage.description}
            </AlertDescription>
          </Alert>
        </div>
      ))}
    </>
  );
}
