import { StdYearCard } from "./std-year-card";

interface StdYearChartsProps extends React.HTMLAttributes<HTMLDivElement> {
  site?: any; // TODO should be ISite , but I need to add monthlyStackedChartsData to the ISite in ts-utils
}

export function StdYearSection({ site }: StdYearChartsProps) {
  
  if (!site.monthlyStackedChartsData) {
    // TODO DISPLAY LOADING COMPONENT;
    return (<></>)
  } else {
    const temp = site.monthlyStackedChartsData.temp;
    const wind = site.monthlyStackedChartsData.wind;
    const dailyRainAcc = site.monthlyStackedChartsData.dailyRainAcc;
    const hourlyRainAcc = site.monthlyStackedChartsData.hourlyRainAcc;
    const snowfall24Hour = site.monthlyStackedChartsData.snowfall24Hour;

    return (
      <>
        <div className="items-start justify-center gap-6 rounded-lg">
          <div className="space-y-1">
            <h2 className="text-2xl font-semibold tracking-tight">
            Standard Historical Year
            </h2>
            <p className="text-sm text-muted-foreground">
              Charts represent a typical year at this location, using data from Dec 1979 to Dec 2023.
            </p>
          </div>
        </div>

        <div className="items-start justify-center gap-6 rounded-lg pt-8 md:grid lg:grid-cols-2 xl:grid-cols-3">
          <div className="grid items-start gap-6 lg:col-span-1">
            <StdYearCard
              title="Temperature"
              description="Daily average air temperature, in Celsius, at 2 meters above ground."
              dataset={temp}
              
            />
          </div>
          <div className="grid items-start gap-6 lg:col-span-1">
            <StdYearCard
              title="Wind"
              description="Daily average wind speed, in m/sec, at 10 meters above ground."
              dataset={wind}
            />
          </div>
          <div className="grid items-start gap-6 xl:col-span-1">
            <StdYearCard
              title="Daily Precipitation"
              description="Total precipitation (rain, showers, snow), in mm/day, with a 0.1 mm precision."
              dataset={dailyRainAcc}
            />
          </div>
        </div>

        {/* <div className="items-start justify-center gap-6 rounded-lg pt-8 md:grid lg:grid-cols-2 xl:grid-cols-3">
          <div className="grid items-start gap-6 lg:col-span-1">
            <StdYearCard
              title="Hourly Precipitation"
              description="Total precipitation (rain, showers, snow), in mm/hour, with a 0.1 mm precision."
              dataset={hourlyRainAcc}
            />
          </div>

          <div className="grid items-start gap-6 lg:col-span-1">
            <StdYearCard
              title="Daily Snowfall "
              description="Snowfall amount in cm/day."
              dataset={snowfall24Hour}
            />
          </div>
        </div> */}

        {/* <div className="items-start justify-center gap-6 rounded-lg pt-8 md:grid lg:grid-cols-2 xl:grid-cols-3">
        
        <div className="grid items-start gap-6 xl:col-span-1">
          <StdYearCard
            title="Visibility"
            description="in km"
            data={temp.data}
            colorMap={temp.colorMap}
          />
        </div> */}

        {/* <div className="grid items-start gap-6 lg:col-span-1">
          <StdYearCard
            title="Wind Gusts"
            description="Average wind in m/sec"
            data={temp.data}
            colorMap={temp.colorMap}
          />
        </div>
      <div className="grid items-start gap-6 lg:col-span-1">
          <StdYearCard
            title="Hourly Snowfall"
            description="in mm/hr"
            data={temp.data}
            colorMap={temp.colorMap}
          />
        </div>

        <div className="grid items-start gap-6 lg:col-span-1">
          <StdYearCard
            title="Wave Height "
            description="in meters"
            data={temp.data}
            colorMap={temp.colorMap}
          />
        </div>
      </div> */}
      </>
    );
  }
}
