import { IClimateHeatmapData, IRules, ISiteHeatmapData } from "@/lib/types";
import {
  ITaskCategory,
  WeatherThresholds,
  WeatherKey,
  Shift,
} from "@ehabitation/ts-utils/browser";
import { firebaseFunction } from "./util";


type IRiskRules = {
  id?: string;
  siteId?: string;
  owner?: string;
  categories: { [name: string]: ITaskCategory };
};

function convertIRulesToIRiskRules(
  rules: IRules,
  riskRuleId: string, // Assuming an ID is provided for the risk rules
  siteId: string,
  owner: string
): IRiskRules {
  const categories: { [name: string]: ITaskCategory } = {};

  // Iterate over each rule in IRules and convert it to a category in IRiskRules
  Object.entries(rules).forEach(([categoryName, rule]) => {
    const thresholds: WeatherThresholds = rule.factors.reduce((acc, curr) => {
      // Assuming factor names are valid WeatherKey strings and factor values can be parsed to numbers
      const value = parseFloat(curr.factor_value);
      if (!isNaN(value)) acc[curr.factor as WeatherKey] = value;
      return acc;
    }, {} as WeatherThresholds);

    // Construct a category
    categories[categoryName] = {
      // It appears each rule already contains an id, which can be directly used.
      // Ensure this id is unique and appropriate for your usage.
      id: categoryName,
      name: categoryName,
      shift: rule.shift as Shift,
      thresholds: thresholds,
      level: "category", // Assuming default to 'category', adjust as needed
      children: [], // This example doesn't provide children details, so initializing as empty
    };
  });

  return {
    id: riskRuleId,
    siteId,
    owner,
    categories: categories,
  };
}

export const getHeatmap = async (site_id: string, rules: IRules) => {
  const generateSiteHeatmap = firebaseFunction("GenerateSiteHeatmap");
  try {
    if (site_id !== undefined) {
      // TODO fix these params.. are they really necessary?
      const riskRules = convertIRulesToIRiskRules(
        rules,
        "ANY_riskRuleId",
        site_id,
        "ANY_userId"
      );
      const categories = riskRules.categories;
      if (riskRules && riskRules.categories) {
        const response = (await generateSiteHeatmap({
          site: site_id,
          riskmatrix: categories,
        })) as {
          data?: {
            heatmap: ISiteHeatmapData;
            error: string[];
          };
        };

        if (response.data !== undefined) {
          return response.data.heatmap;
        } else {
          return null; // Explicitly return null to indicate no data.
        }
      }
    }
    return null;
  } catch (err) {
    console.error(err);
    return null; // Consider whether returning null is appropriate in case of error.
  }
};

export const getClimateHeatmap = async (
  site_id: string,
  rules: IRules,
  start_year = "",
  end_year = "",
  start_month = "",
  end_month = ""
) => {
  const generateClimateHeatmap = firebaseFunction("GenerateClimateHeatmap");
  try {
    if (site_id !== undefined) {
      // TODO fix these params.. are they really necessary?
      const riskRules = convertIRulesToIRiskRules(
        rules,
        "ANY_riskRuleId",
        site_id,
        "ANY_userId"
      );
      const categories = riskRules.categories;
      if (riskRules && riskRules.categories) {
        const response = (await generateClimateHeatmap({
          site: site_id,
          riskmatrix: categories,
          start_year: start_year,
          end_year: end_year,
          start_month: start_month,
          end_month: end_month,
        })) as {
          data?: {
            heatmap: IClimateHeatmapData;
            error: string[];
          };
        };

        if (response.data !== undefined) {
          return response.data.heatmap;
        } else {
          return null; // Explicitly return null to indicate no data.
        }
      }
    }
    return null;
  } catch (err) {
    console.error(err);
    return null; // Consider whether returning null is appropriate in case of error.
  }
};
