import { ISiteHeatmapData } from "@/lib/types";
import { ISite } from "@ehabitation/ts-utils/browser";

import { HeatmapSection } from "./heatmap/heatmap-section";
import { SiteSummaryCards } from "./site-summary-cards";
import { StdYearSection } from "./std-year/std-year-section";
import AnimatedUnderline from "../ui/animations/underline-animation";
import { useEvents } from "@/context/events-context";
import { useEffect } from "react";

interface OverviewMainProps extends React.HTMLAttributes<HTMLDivElement> {
  site?: ISite; // TODO define the type properly...
  siteHeatmap?: ISiteHeatmapData;
}

export function OverviewMain({ site, siteHeatmap }: OverviewMainProps) {
  const {events} = useEvents()
  useEffect(() => {
    if(site)
        events.view_historic_section(site.id)
  }, [site])
  if (site === undefined) return <>No site selected.</>;
  return (
    <>
      <div>
        {/* <h2 className="text-2xl font-semibold tracking-tight pb-4">XXXXXXXX</h2> */}
        <div className="text-xs text-muted-foreground">
          <h6 className="pb-2">
            Historical dataset:{" "}
            <AnimatedUnderline>
              <a
                href="https://www.ecmwf.int/en/forecasts/dataset/ecmwf-reanalysis-v5"
                target="_blank"
              >
                ECMWF Reanalysis v5 (ERA5)
              </a>
            </AnimatedUnderline>
          </h6>
          <p className="pb-2">
            The ERA5 v5 is based on reanalysis datasets and uses a combination
            of weather station, aircraft, buoy, radar, and satellite
            observations to create a comprehensive record of past weather
            conditions. These datasets are able to fill in gaps by using
            mathematical models to estimate the values of various weather
            variables. As a result, reanalysis datasets are able to provide
            detailed historical weather information for locations that may not
            have had weather stations nearby, such as rural areas or the open
            ocean.
          </p>
          <p className="pb-2">
            ERA5 is the fifth generation ECMWF atmospheric reanalysis of the
            global climate covering the period from January 1940 to present.
            EHAB is currently using 1979 to Dec 2023. ERA5 is produced by the
            Copernicus Climate Change Service (C3S) at ECMWF. ERA5 provides
            hourly estimates of a large number of atmospheric, land and oceanic
            climate variables. The data cover the Earth on a 31km grid and
            resolve the atmosphere using 137 levels from the surface up to a
            height of 80km. ERA5 includes information about uncertainties for
            all variables at reduced spatial and temporal resolutions.
          </p>
        </div>
      </div>
      {/* <SiteSummaryCards site={site} /> */}

      <>
        {/* <RulesSummaryCards site={site} /> */}
        <div className="flex items-center justify-between pt-10"></div>

        <HeatmapSection heatmap={siteHeatmap} />
      </>

      <div className="flex items-center justify-between pt-14"></div>
      <StdYearSection site={site} />
    </>
  );
}
