import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";

type FirebaseFunctions =
//   | "deleteSite"
  | "resetUserPassword"
//   | "registerUser"
//   | "deleteDivision"
//   | "createNewDivision"
//   | "updateDivision"
//   | "masterGrantRole"
//   | "GenerateRiskCalendars"
//   | "GenerateHistoricData"
//   | "GenerateTaskExport"
//   | "GenerateSafranDowntime"
//   | "GenerateAcumenRiskPrePostCalendar"
//   | "GenerateP6RiskCalendar"
//   | "GeneratePRARiskCalendar"
//   | "RunGenerateAdjustedCalendar"
//   | "createNewProject"
//   | "updateProject"
//   | "deleteProject"
  // | "createNewSite"
//   | "updateSite"
//   | "deleteSite"
//   | "createNewUser"
//   | "updateUser"
//   | "deleteUser"
//   | "createWeatherBaselineReport"
  | "GenerateHistoricMonthlyRanges"
  | "GenerateSiteHeatmap"
  | "GenerateClimateHeatmap"
//   | "GenerateBaselinePDF"
//   | "GenerateSiteWeatherCalendar"
//   | "ImportRiskMatrix"
//   | "ExportRiskMatrix"
//   | "PRAPlnxMapping"
//   | "CopyPlan"
  | "CopyRiskMatrix"
//   | "SafranProjectMapping"
//   | "SafranRiskMapping"
//   | "GenerateHistoricStats";

export const firebaseFunction = <T>(functionName: FirebaseFunctions) =>
  httpsCallable<any, T>(functions, functionName);
