import {
  Menubar,
  MenubarCheckboxItem,
  MenubarContent,
  MenubarItem,
  MenubarLabel,
  MenubarMenu,
  MenubarRadioGroup,
  MenubarRadioItem,
  MenubarSeparator,
  MenubarShortcut,
  MenubarSub,
  MenubarSubContent,
  MenubarSubTrigger,
  MenubarTrigger,
} from "@/components/ui/menubar";
import { AuthContext } from "@/context/auth-context";
import { useContext } from "react";

export function Menu() {
  const { currentUser, signOut } = useContext(AuthContext);

  return (
    <div className="pt-2">

    
    <Menubar className="flex justify-between rounded-none border-b border-none px-2 lg:px-4">
      <div className="flex px-2 pb-2">
      <img src="/ehab-logo.svg" className="h-[30px]"/>
      </div>

      <div className="flex pb-2">
        <MenubarMenu>
          <MenubarTrigger className="hidden md:block" onClick={signOut}>
            Hey {currentUser?.email}, Signout
          </MenubarTrigger>
        </MenubarMenu>
      </div>
    </Menubar>
    </div>
  );
}
