import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../ui/card";

import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

interface StdYearCardProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  description: string;
  dataset: any;
}

export function StdYearCard({ title, description, dataset }: StdYearCardProps) {
  
  if (!dataset || dataset.data.length === 0) return <>No {title} data.</>;

  const data = dataset.data;
  const colorMap: { [key: string]: string } = dataset.colorMap;
  const keys = Object.keys(data[0]).filter((key) => key !== "month");

  const legentOptions = {
    iconSize: 2,
  }
  
  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-lg font-semibold tracking-tight">{title}</CardTitle>
        <CardDescription className="text-xs">{description}</CardDescription>
      </CardHeader>
      <CardContent className="pl-2">
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            width={500}
            height={400}
            data={data.map((d: { [x: string]: number; }) => ({
              ...d,
              ...Object.keys(d).reduce<{ [key: string]: number }>(
                (acc, key) => {
                  if (key !== "month" && typeof d[key] !== "string")
                    acc[key] = d[key];
                  return acc;
                },
                {}
              ),
            }))}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip labelStyle={{fontSize: "12px", fontWeight:"bold"}} itemStyle={{ color: "black", fontSize: "12px"}}/>
            <Legend margin={{top: 100 }} iconSize={10} align="left" wrapperStyle={{fontSize: "12px"}}
            formatter={(value, entry, index) => <span className="text-muted-foreground">{value}</span>}
            />
            {keys.map((key) => (
              <Bar
                key={key}
                dataKey={key}
                stackId="a"
                fill={colorMap[key] || "#8884d8"} // Fallback color if key not found in colorMap
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
}
